import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { SectionListTitleStyled } from 'src/presentation/modules/notifications-flyout/components/notifications-panel/notifications-panel.styled';
import { ReminderCardContainer } from 'src/presentation/modules/notifications-flyout/components/reminders-dashboard/reminder-card/reminder-card.container';
import { NotificationPlaceholderComponent } from 'src/presentation/modules/notifications-flyout/components/shared/notification-placeholder/notification-placeholder.component';
import { Spacing } from 'src/resources/styles/spacing';
import { multiple } from 'src/utils/array.utils';

interface ReminderListSectionProps {
    title: string;
    fetchingReminders: boolean;
    reminders: Reminder[];
}

export const ReminderListSection: FC<ReminderListSectionProps> = observer(
    ({ title, fetchingReminders, reminders }) => {
        const shouldDisplaySectionTitle =
            fetchingReminders || (!fetchingReminders && !!reminders.length);

        return (
            <Box
                sx={{
                    marginY: Spacing.s2,
                }}
            >
                {shouldDisplaySectionTitle && (
                    <SectionListTitleStyled>{title}</SectionListTitleStyled>
                )}
                <ul className="h-full list-none">
                    {fetchingReminders
                        ? multiple(
                              (index: number) => (
                                  <NotificationPlaceholderComponent
                                      key={index}
                                  />
                              ),
                              2,
                          )
                        : reminders.map((reminder) => (
                              <ReminderCardContainer
                                  key={reminder.id}
                                  reminder={reminder}
                              />
                          ))}
                </ul>
            </Box>
        );
    },
);
