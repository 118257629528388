import { observer } from 'mobx-react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'src/presentation/shared/link/link.component';

import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { Button } from '@buildingradar/ui_library/button';
import { CircleCheckBig, Pencil } from 'lucide-react';

interface NotificationCardProps {
    linkUrl: string;
    headerBadge: ReactNode;
    title: string;
    subTitle: string;
    when: string;
    onLinkClickedHandler: () => void;
    onEdit?: () => void;
    onDismiss?: () => void;
}

export const NotificationCard: FC<NotificationCardProps> = observer(
    ({
        linkUrl,
        headerBadge,
        title,
        subTitle,
        when,
        onLinkClickedHandler,
        onEdit,
        onDismiss,
    }) => {
        const { t } = useTranslation();

        return (
            <li className="notification-card group flex w-full items-center rounded-md border-b border-b-gray-300 px-2 py-4 hover:bg-gray-200">
                <Link
                    onClick={onLinkClickedHandler}
                    href={linkUrl}
                    className="link"
                >
                    <div className="flex gap-4">
                        <div className="">{headerBadge}</div>
                        <div className="mr-2 text-base tracking-wide">
                            <h2>{title}</h2>
                            <span className="font-medium text-gray-800 group-hover:text-indigo-600">
                                {subTitle}
                            </span>

                            <div className="text-sm">
                                <span className="tracking-wide">{when}</span>
                            </div>
                        </div>
                    </div>
                </Link>
                <>
                    {onEdit && (
                        <Tooltip content={t('common.edit')}>
                            <Button
                                variant="ghost"
                                size="icon"
                                className="rounded-full"
                                onClick={onEdit}
                            >
                                <Pencil className="size-5 text-gray-600" />
                            </Button>
                        </Tooltip>
                    )}
                    {onDismiss && (
                        <Tooltip
                            content={t('next_steps.complete_action.tooltip')}
                        >
                            <Button
                                variant="ghost"
                                size="icon"
                                className="rounded-full"
                                onClick={onDismiss}
                            >
                                <CircleCheckBig className="size-5 text-gray-600" />
                            </Button>
                        </Tooltip>
                    )}
                </>
            </li>
        );
    },
);
