import { makeAutoObservable } from 'mobx';
import { AchievementMap } from 'src/app-features/achievements/domain/model/achievement.model';

import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { Search } from 'src/domain/models/search/search.model';
import { User } from 'src/domain/models/user/user.model';

export interface UserStore {
    user?: User;
    isLoading: boolean;
    error?: Error;
    setError: (error: Error) => void;
    setLoading: (flag: boolean) => void;
    setUser: (user?: User) => void;
    searchTags: string[];
    addToSearches: (search: Search) => void;
    removeFromSearches: (searchId: number) => void;
    getColleague: (id: number) => Colleague | undefined;
    /**
     * Holds a list of active users. It's ordered alphabetically and the current
     * logged user is always the first, regardless their name
     */
    assignableUsers: Colleague[];
    userAchievements?: AchievementMap;
    setUserAchievements: (achievements?: AchievementMap) => void;
}

export class UserStoreImpl implements UserStore {
    user: User | undefined = undefined;
    isLoading = false;
    error: Error | undefined = undefined;
    userAchievements: AchievementMap | undefined = undefined;

    setUser = (user?: User) => {
        this.user = user;
        this.error = undefined;
    };

    setLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    setError = (error?: Error) => {
        this.error = error;
    };

    get searchTags() {
        if (!this.user) {
            return [];
        }
        return this.user.searches.map((search) => `${search.id}`);
    }

    addToSearches = (search: Search) => {
        if (!this.user) {
            return;
        }
        const { searches } = this.user;
        const index = searches.findIndex(({ id }) => id === search.id);
        if (index < 0) {
            searches.push(search);
        }
    };

    removeFromSearches = (searchId: number) => {
        if (!this.user) {
            return;
        }
        const { searches } = this.user;
        const index = searches.findIndex(({ id }) => id === searchId);
        if (index >= 0) {
            searches.splice(index, 1);
        }
    };

    getColleague = (id: number) => {
        return this.user?.colleagues.find(({ itemId }) => itemId === id);
    };

    get assignableUsers(): Colleague[] {
        const currentUser = this.user;

        if (!currentUser) {
            return [];
        }
        const { colleagues } = currentUser;

        const currentUserAsColleague = colleagues.find(
            ({ itemId }) => itemId === currentUser.itemId,
        );

        const allOtherActiveColleagues = colleagues
            .filter(
                ({ itemId, active }) => itemId !== currentUser.itemId && active,
            )
            .sort((userA, userB) =>
                userA.firstName.localeCompare(userB.firstName),
            );

        if (currentUserAsColleague) {
            allOtherActiveColleagues.unshift(currentUserAsColleague);
        }

        return allOtherActiveColleagues;
    }

    setUserAchievements = (achievements?: AchievementMap) => {
        this.userAchievements = achievements;
    };

    constructor() {
        makeAutoObservable(this);
    }
}
