import { withFeatures } from 'src/utils/component.utils';

import { ReminderCard } from './reminder-card.component';

export const ReminderCardContainer = withFeatures(ReminderCard)((f) => ({
    language: f.authFeature.user?.language,
    toggleNotificationsOpened: f.notificationsFeature.toggleNotificationsOpened,
    openReminderFlyout: f.remindersFeature.openReminderFlyout,
    markAsCompleted: f.remindersFeature.markAsCompleted,
}));
