import { BrInfotip } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Link } from 'src/presentation/shared/link/link.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import {
    StyledItemWrapper as StyledNavItemWrapper,
    StyledBottomCorner,
    StyledItemShadow as StyledNavItemShadow,
    StyledIconWrapper,
} from './nav-item.styled';
import { Localized } from 'src/presentation/shared/localized/localized';

interface NavItemProps {
    name: string;
    className: string;
    icon: JSX.Element;
    route: string;
    isActive: boolean;
    isShadowVisible?: boolean;
    hideTooltip?: boolean;
    showNewBadge?: boolean;
    onClick?: () => void;
}

export const NavItemComponent: FC<NavItemProps> = observer(
    ({
        name,
        icon,
        route,
        isActive,
        className,
        isShadowVisible = false,
        hideTooltip = false,
        showNewBadge = false,
        onClick,
    }) => {
        const isTablet = useMediaQuery(MediaQuery.tablet);
        const shouldHideTooltip = hideTooltip;

        return (
            <BrInfotip
                placement="right"
                showArrow
                content={name}
                trigger={shouldHideTooltip ? 'disabled' : 'onHover'}
            >
                <StyledNavItemWrapper
                    isActive={isActive}
                    className={className}
                    onClick={onClick}
                >
                    {isActive && (
                        <>
                            <StyledBottomCorner />
                            <StyledNavItemShadow
                                hasAnimation={!isTablet}
                                isVisible={isShadowVisible}
                            />
                        </>
                    )}
                    <Link href={route}>
                        <StyledIconWrapper>{icon}</StyledIconWrapper>
                        {showNewBadge && (
                            <div className="absolute bottom-2 right-0.5 z-10 flex h-4 w-auto flex-row items-center justify-center rounded bg-pink-500 px-1 py-0.5 text-xs text-white">
                                <Localized>common.new</Localized>
                            </div>
                        )}
                    </Link>
                </StyledNavItemWrapper>
            </BrInfotip>
        );
    },
);
