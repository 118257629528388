import { graphqlProcessWsUrl } from 'src/data/api/graphql/graphql-client.wrapper';

let isWebsocketAvailable: boolean | undefined = undefined;
const checkWs = () => {
    return new Promise<boolean>((resolve) => {
        try {
            const wsUri = graphqlProcessWsUrl;
            const websocket = new WebSocket(wsUri, 'graphql-transport-ws');

            websocket.onopen = function () {
                websocket.close();
                isWebsocketAvailable = true;
                resolve(true);
            };

            websocket.onerror = function () {
                isWebsocketAvailable = false;
                resolve(false);
            };
        } catch {
            isWebsocketAvailable = false;
            resolve(false);
        }
    });
};

export const checkWebSocket = async () =>
    isWebsocketAvailable ?? (await checkWs());
