import { BrTabs } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { buildTabConfiguration } from './notifications-tab.helper';

export const NotificationsTab: FC = observer(() => {
    const { t } = useTranslation();

    const configuration = buildTabConfiguration(t);

    const [selectedTab, setSelectedTab] = useState<string>('reminders');

    return (
        <BrTabs
            selectedValue={selectedTab}
            configurations={configuration}
            onTabChanged={setSelectedTab}
        />
    );
});
