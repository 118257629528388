import { FC } from 'react';

import { FeatureFlag } from 'src/data/services/feature-flags/feature-flags.model';
import { useFeatureFlagBool } from 'src/utils/hooks/use-feature-flag';
import { IChildren } from 'src/utils/react.utils';

interface FeatureFlagControlProps extends IChildren {
    featureKey: FeatureFlag;
    not?: boolean; //set to true if you want to render children when the flag is false
}

export const FeatureFlagControl: FC<FeatureFlagControlProps> = ({
    featureKey,
    not,
    children,
}) => {
    let featureIsEnabled = useFeatureFlagBool(featureKey);

    if (not) {
        featureIsEnabled = !featureIsEnabled;
    }

    return featureIsEnabled ? <>{children}</> : null;
};
