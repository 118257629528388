import { print } from 'graphql';

import { AbortParams } from 'src/data/api/api-client';
import { createNeuralSearchResponse, NeuralSearchResponse } from './model';
import { SearchGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    NeuralSearchDocument,
    NeuralSearchSubscription,
    NeuralSearchSubscriptionVariables,
} from 'src/data/api/graphql/br_search/generated/graphql-sdk';

interface RunNeuralSearchParams extends AbortParams {
    prompt: string;
    startDate?: Date;
    endDate?: Date;
    onNextValue?: (value: NeuralSearchResponse) => void;
}

export interface INeuralSearchApi {
    runNeuralSearch: (
        params: RunNeuralSearchParams,
    ) => Promise<NeuralSearchResponse[]>;
}

export const createNeuralSearchApi = (
    searchGqlSk: SearchGqlSdk,
): INeuralSearchApi => {
    const runNeuralSearch = async ({
        prompt,
        startDate,
        endDate,
        signal,
        onNextValue,
    }: RunNeuralSearchParams): Promise<NeuralSearchResponse[]> => {
        const results: NeuralSearchResponse[] = [];
        return new Promise((resolve, reject) => {
            searchGqlSk.runSubscription<NeuralSearchSubscription>(
                {
                    query: print(NeuralSearchDocument),
                    variables: {
                        prompt,
                        startDate,
                        endDate,
                    } as NeuralSearchSubscriptionVariables,
                },
                (value) => {
                    const response = createNeuralSearchResponse(
                        value.neuralSearchSearch,
                    );
                    results.push(response);
                    onNextValue?.(response);
                },
                () => resolve(results),
                (error) => {
                    return reject(error);
                },
                signal,
            );
        });
    };
    return {
        runNeuralSearch,
    };
};
