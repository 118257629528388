import { useMediaQuery } from '@mui/material';
import { SwipeableDrawer } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';

import { MediaQuery } from 'src/resources/styles/media.mixins';
import { usePrevious } from 'src/utils/hooks/use-previous';

import { MobileMenuContainer } from './components/mobile-menu/mobile-menu.container';
import { createSwipableClasses } from './mobile-menu-swiper.classes';
import { getRoot } from 'src/utils/element.utils';

interface MobileMenuSwiperComponentProps {
    isOpened: boolean;
    toggleMenuOpen: (flag?: boolean) => void;
    toggleNotifications: (flag: boolean) => void;
}

export const MobileMenuSwiperComponent: FC<MobileMenuSwiperComponentProps> =
    observer(({ toggleMenuOpen, isOpened, toggleNotifications }) => {
        const isTablet = useMediaQuery(MediaQuery.tablet);
        const previousIsTablet = usePrevious(isTablet);

        useEffect(() => {
            if (isOpened && !isTablet) {
                toggleMenuOpen(false);
            }
        }, [isOpened, isTablet, toggleMenuOpen]);

        useEffect(() => {
            if (isTablet !== previousIsTablet) {
                // i.e. when we go over breakpoint we should close notifications
                toggleNotifications(false);
            }
        }, [isTablet, previousIsTablet, toggleNotifications]);

        const openMenu = useCallback(
            () => toggleMenuOpen(true),
            [toggleMenuOpen],
        );
        const closeMenu = useCallback(() => {
            toggleMenuOpen(false);
            toggleNotifications(false);
        }, [toggleMenuOpen, toggleNotifications]);

        const fullWidthMenu = useMediaQuery(MediaQuery.fullWidthMenu);

        const classes = createSwipableClasses(fullWidthMenu)();

        return (
            <SwipeableDrawer
                sx={classes}
                container={getRoot()}
                open={isOpened}
                onOpen={openMenu}
                onClose={closeMenu}
            >
                <MobileMenuContainer />
            </SwipeableDrawer>
        );
    });
