import { SearchResultResponseQl } from 'src/data/api/graphql/br_search/generated/graphql-sdk';

export enum NeuralSearchCategory {
    Agriculture = 'AGRICULTURE',
    Commercial = 'COMMERCIAL',
    CulturalEvent = 'CULTURAL_EVENT',
    EducationScience = 'EDUCATION_SCIENCE',
    Health = 'HEALTH',
    HistoricalReligious = 'HISTORICAL_RELIGIOUS',
    Hospitality = 'HOSPITALITY',
    Industrial = 'INDUSTRIAL',
    Infrastructure = 'INFRASTRUCTURE',
    Landscaping = 'LANDSCAPING',
    Office = 'OFFICE',
    PublicServiceAdministration = 'PUBLIC_SERVICE_ADMINISTRATION',
    Residential = 'RESIDENTIAL',
    Sport = 'SPORT',
}

export interface NeuralSearchResponse {
    searchId: string;
    order: number;
    country: string;
    state: string;
    city: string;
    typeOfProject: NeuralSearchCategory[];
    searchResult: NeuralSearchResult;
}

interface NeuralSearchResult {
    itemId: string;
    title: string;
    summary: string[];
    city: string;
    state: string;
    country: string;
    typeOfProject: NeuralSearchCategory[];
    publishedDate: Date;
    links: string[];
}

export const createNeuralSearchResponse = (
    raw: SearchResultResponseQl,
): NeuralSearchResponse => {
    const {
        searchId,
        order,
        country,
        state,
        city,
        typeOfProject,
        searchResult,
    } = raw;
    return {
        searchId,
        order,
        country,
        state,
        city,
        typeOfProject: typeOfProject?.map(
            (type) => type as unknown as NeuralSearchCategory,
        ),
        searchResult: {
            itemId: searchResult.itemId,
            title: searchResult.title,
            summary: searchResult.summary,
            city: searchResult.city,
            state: searchResult.state,
            country: searchResult.country,
            typeOfProject: searchResult.typeOfProject?.map(
                (type) => type as unknown as NeuralSearchCategory,
            ),
            publishedDate: new Date(searchResult.publishedDate),
            links: searchResult.links,
        },
    };
};
