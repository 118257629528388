import { makeAutoObservable } from 'mobx';

import { Mention } from 'src/domain/models/mention/mention.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';

export interface NotificationsStore {
    mentions: Mention[];
    reminders: Reminder[];
    setMentions: (mentions: Mention[]) => void;
    setReminders: (reminder: Reminder[]) => void;
    dismissReminder: (reminderId: string) => void;
    addCreatedReminder: (reminder: Reminder) => void;
    updateReminder: (reminder: Reminder) => void;
}

export class NotificationsStoreImpl implements NotificationsStore {
    mentions: Mention[] = [];
    reminders: Reminder[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setMentions = (mentions: Mention[]) => {
        this.mentions = mentions;
    };

    setReminders = (reminder: Reminder[]) => {
        this.reminders = reminder;
    };

    dismissReminder = (reminderId: string) => {
        this.reminders = this.reminders.filter(({ id }) => id !== reminderId);
    };

    addCreatedReminder = (reminder: Reminder) => {
        this.reminders.push(reminder);
    };

    updateReminder = (reminder: Reminder) => {
        this.reminders = this.reminders.map((r) =>
            r.id === reminder.id ? reminder : r,
        );
    };
}
