import { MenuItemConfiguration } from '@buildingradar/br_component_lib';
import { makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { CustomParametersApi } from 'src/data/api/custom-parameters/custom-parameters.api';
import { DealsApi } from 'src/data/api/deal/deal.api';
import { DealAttribute } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { IDealBatchOperationsStore } from 'src/data/stores/deal-batch-operations/deal-batch-operations.store';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { IPipelinePerformanceViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/pipeline-performance-view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { filterValidNonNullableParameters } from 'src/domain/features/custom-parameters/custom-parameters.utils';
import {
    CustomParameter,
    CustomParameterType,
} from 'src/domain/models/custom-parameter/custom-parameter.model';
import {
    RefetchPipelineViewDataSource,
    getPipelineViewDataTypeBasedOnVisualizationMode,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { sortByOrder } from 'src/utils/array.utils';
import { handleRequest } from 'src/utils/handle-request.utils';

export interface BulkEditableDealParams extends Omit<CustomParameter, 'type'> {
    type: CustomParameterType | 'owner-selection';
}

export interface IBulkDealEditFeature {
    isModalOpened: boolean;
    bulkEditableParamsOptions: MenuItemConfiguration[];
    selectedEditableParam: BulkEditableDealParams;
    selectedParameterName: string;
    currentParameterValue: string | number;
    openModal: () => void;
    closeModal: (oldTableUsage: boolean) => void;
    setSelectedParameterName: (parameter: string) => void;
    setParameterValue: (value: number | string) => void;
    applyDealBulkParametersChanges: () => void;
}

const DealAttributesMap: { [param: string]: DealAttribute } = {
    'deal-owner': DealAttribute.Assignee,
    'estimated-deal-value': DealAttribute.DealValue,
    'deal-notes': DealAttribute.Notes,
};

export class BulkDealEditFeature implements IBulkDealEditFeature {
    t: TFunction<'translation', undefined> = getI18n().t;
    isModalOpened = false;
    selectedParameterName = this.bulkEditableParamsOptions.length
        ? this.bulkEditableParamsOptions[0].value
        : '';
    currentParameterValue: string | number = 0;

    constructor(
        private configurationStore: IAccountConfigurationStore,
        private dealBatchOperationStore: IDealBatchOperationsStore,
        private pipelineViewPreferenceStore: IPipelinePerformanceViewPreferencesStore,
        private dealsStore: DealsStore,
        private dealApi: DealsApi,
        private customParameterApi: CustomParametersApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    get bulkEditableParams(): BulkEditableDealParams[] {
        if (!this.configurationStore) {
            return [];
        }

        const editableParams: BulkEditableDealParams[] = [
            {
                name: 'deal-owner',
                label: this.t('deal_view.user_assignment.label'),
                acceptedValues: null,
                defaultValue: '0',
                type: 'owner-selection',
                value: '0',
                isTag: false,
                isVisibleInDealPage: false,
                order: 0,
            },
            {
                name: 'estimated-deal-value',
                label: this.t('deal_view.field.deal_value'),
                acceptedValues: null,
                defaultValue: '',
                type: CustomParameterType.STRING,
                value: '',
                isTag: false,
                isVisibleInDealPage: false,
                order: 0,
            },
            {
                name: 'deal-notes',
                label: this.t('deal_view.field.deal_note'),
                acceptedValues: null,
                defaultValue: '',
                type: CustomParameterType.STRING,
                value: '',
                isTag: false,
                isVisibleInDealPage: false,
                order: 0,
            },
        ];

        const customDealParams = sortByOrder(
            this.configurationStore.accountConfiguration?.dealCustomFields ??
                [],
        );

        if (customDealParams?.length) {
            editableParams.push(
                ...filterValidNonNullableParameters(customDealParams),
            );
        }

        return editableParams;
    }

    get bulkEditableParamsOptions(): MenuItemConfiguration[] {
        return this.bulkEditableParams.map(({ name, label }) => ({
            label,
            value: name,
        }));
    }

    get selectedEditableParam(): BulkEditableDealParams {
        return this.bulkEditableParams.find(
            ({ name }) => name === this.selectedParameterName,
        )!;
    }

    openModal = () => {
        this.isModalOpened = true;
        this.dealBatchOperationStore.setBatchOperationStatus(false);
        this.selectedParameterName = this.bulkEditableParamsOptions[0].value;
        this.currentParameterValue = 0;
        this.dealBatchOperationStore.initializeBatchOperationSteps();
    };

    closeModal = (oldTableUsage: boolean) => {
        this.isModalOpened = false;

        if (this.dealBatchOperationStore.isBatchOperationSuccessful) {
            if (oldTableUsage) {
                this.dealsStore.setNeedToRequestDealsList(true);
            } else {
                this.pipelineViewPreferenceStore.setRefetchPipelineViewDataState(
                    {
                        active: true,
                        refetchType:
                            getPipelineViewDataTypeBasedOnVisualizationMode(
                                this.pipelineViewPreferenceStore
                                    .visualizationModePreference,
                            ),
                        source: RefetchPipelineViewDataSource.bulkEditDeals,
                    },
                );
            }
        }
    };

    setSelectedParameterName = (parameter: string) => {
        this.selectedParameterName = parameter;

        const parameterDefaultValue = this.selectedEditableParam.defaultValue;
        this.setParameterValue(parameterDefaultValue ?? '');
    };

    setParameterValue = (value: number | string) => {
        this.currentParameterValue = value;
    };

    onBulkUpdateSucceed = (updatedDealIds: string[]) => {
        /** Cleaning old deals from dealMap so when opening the deal the app re-fetches
         * the deal from the server with up to date data
         */
        updatedDealIds.forEach((id) => this.dealsStore.dealsMap.delete(id));
        this.dealBatchOperationStore.setBatchOperationStatus(true);
        this.dealBatchOperationStore.goToNextStep();
    };

    onBulkUpdateFailed = (error?: Error) => {
        if (error) {
            this.dealBatchOperationStore.setBatchOperationStatus(false);
            this.dealBatchOperationStore.goToNextStep();
            this.baseStore.onRequestFailed(
                'apply-deal-bulk-parameters-change',
                error,
            );
        }
    };

    applyDealBulkParametersChanges = () => {
        const dealIds = this.dealBatchOperationStore.selectedDealIds;
        const value = this.currentParameterValue;

        if (
            Object.keys(DealAttributesMap).some(
                (attr) => attr === this.selectedParameterName,
            )
        ) {
            const attribute = DealAttributesMap[this.selectedParameterName];

            handleRequest(
                this.dealApi.bulkUpdateDealAttributes,
                {
                    attribute,
                    dealIds,
                    value,
                },
                this.onBulkUpdateSucceed,
                this.dealBatchOperationStore.setUpdateInProgress,
                this.onBulkUpdateFailed,
            );
        } else {
            handleRequest(
                this.customParameterApi.bulkUpdateParametersValue,
                {
                    dealIds,
                    name: this.selectedParameterName,
                    value: value.toString(),
                },
                this.onBulkUpdateSucceed,
                this.dealBatchOperationStore.setUpdateInProgress,
                this.onBulkUpdateFailed,
            );
        }
    };
}
