import { clsx, type ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const extendedConfig = extendTailwindMerge({
    extend: {
        /* because we have a custom size `text-xxs` in our project, we need to let twMerge know about it
        /* so when applying for example `text-xxs and text-lime-500`, twMerge will understand that `text-xxs` is a valid class
        /* and will not remove it from the final class list. Ideally we should have all the custom classes in the tailwind config
        /* but we are using cn just in a couple of places so for now that's ok.
        */
        classGroups: {
            'font-size': ['text-xxs'],
        },
    },
});

export function cn(...inputs: ClassValue[]) {
    return extendedConfig(clsx(inputs));
}
