import { useCallback, useRef } from 'react';

export const useDebounceCallback = <Args extends unknown[]>(
    handler: ((...args: Args) => void) | undefined,
    time: number,
    deps: unknown[],
) => {
    const timeoutRef = useRef<number>();

    const callback = useCallback(
        (...args: Args) => {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = window.setTimeout(
                () => handler?.(...args),
                time,
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handler, time, ...deps],
    );

    return callback;
};
