import { SwipeableDrawer } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';

import { NotificationsPanelContainer } from './components/notifications-panel/notifications-panel.container';
import {
    createNotificationsSwipableClasses,
    StyledNotificationContent,
    useBackdropClasses,
} from './notifications-modal.styled';
import { getRoot } from 'src/utils/element.utils';

interface NotificationsFlyoutProps {
    toggleNotificationsOpened: (flag: boolean) => void;
    isOpened: boolean;
}

export const NotificationsFlyout: FC<NotificationsFlyoutProps> = observer(
    ({ toggleNotificationsOpened, isOpened }) => {
        useEffect(() => {
            return () => toggleNotificationsOpened(false);
        }, [toggleNotificationsOpened]);

        const toggleOpened = useCallback(() => {
            toggleNotificationsOpened(!isOpened);
        }, [isOpened, toggleNotificationsOpened]);

        const classes = createNotificationsSwipableClasses(false)();
        const backdropClasses = useBackdropClasses();
        return (
            <SwipeableDrawer
                container={getRoot()}
                keepMounted={false}
                BackdropProps={{
                    sx: backdropClasses,
                }}
                transitionDuration={300}
                onOpen={toggleOpened}
                sx={classes}
                open={isOpened}
                onClose={toggleOpened}
            >
                <StyledNotificationContent>
                    <NotificationsPanelContainer />
                </StyledNotificationContent>
            </SwipeableDrawer>
        );
    },
);
