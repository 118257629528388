import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type AdminCompanyResetUserSettingsInputQl = {
  companyId: Scalars['Int']['input'];
  setting: UserSettingName;
};

export type AdminImpersonateUserResultQl = {
  cookieName: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user: SessionUserWithExtraAttribsQl;
};

export enum AdminPermission {
  CanAccessAdminPanel = 'CAN_ACCESS_ADMIN_PANEL',
  CanAccessManuel = 'CAN_ACCESS_MANUEL',
  CanAccessPrescreening = 'CAN_ACCESS_PRESCREENING',
  CanImpersonate = 'CAN_IMPERSONATE'
}

export type AdminQl = {
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  externalAuthProvider: Scalars['String']['output'];
  externalAuthUserId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  itemId: Scalars['Int']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  permissions: Array<AdminPermission>;
  roles: Array<AdminRole>;
  updated: Scalars['DateTime']['output'];
};

export enum AdminRole {
  Admin = 'ADMIN',
  Manueler = 'MANUELER',
  Prescreener = 'PRESCREENER'
}

export type BaseUserQl = {
  active: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  featureToggles: Array<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  isCsManager?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['Int']['output'];
  jobRoles: Array<UserJobRoles>;
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  settings: UserSettingsQl;
};

export type ColleagueQl = {
  active: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  itemId: Scalars['Int']['output'];
  jobRoles: Array<UserJobRoles>;
  lastName: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
};

export type CompanySettingsQl = {
  currency?: Maybe<Currency>;
  merging?: Maybe<Scalars['Boolean']['output']>;
  preScreening?: Maybe<Scalars['Boolean']['output']>;
  useAppDotBr?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateUserApiTokenInputQl = {
  name: Scalars['String']['input'];
};

export enum Currency {
  Eur = 'EUR',
  Usd = 'USD'
}

export type ImpersonateUserInputQl = {
  email: Scalars['String']['input'];
};

export type ImpersonatorInfoQl = {
  email: Scalars['String']['output'];
  itemId: Scalars['Int']['output'];
};

export type LinkedFilterModuleQl = {
  filter: Scalars['String']['output'];
  key: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type LoginInputQl = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum LoginMethod {
  Authentication = 'AUTHENTICATION',
  ExternalApi = 'EXTERNAL_API',
  Impersonation = 'IMPERSONATION'
}

export type Mutation = {
  /** deprecated */
  adminCompanyResetUserSetting: ResultQl;
  /** deprecated */
  adminCreateCompany: UserCompanyQl;
  /** deprecated */
  adminImpersonateUser: AdminImpersonateUserResultQl;
  /** deprecated */
  adminSendPasswordReset: ResultQl;
  /** deprecated */
  adminUpdateCompanyActive: ResultQl;
  /** deprecated */
  adminUpdateCompanyName: ResultQl;
  /** deprecated */
  adminUpdateCompanyUsePreScreening: ResultQl;
  /** deprecated */
  adminUpdateUser: UserQl;
  createUserApiToken: UserApiTokenQl;
  deleteUserApiToken: ResultQl;
  invalidateAllSessions: ResultQl;
  loginWithStatus: SessionUserAuthenticationResultQl;
  logout: ResultQl;
  sendPasswordReset: ResultQl;
  setXsrfToken: ResultQl;
  updatePassword: ResultQl;
  updatePasswordFromResetToken: ResultQl;
  updateUserLanguage: ResultQl;
  updateUserSetting: ResultQl;
};


export type MutationAdminCompanyResetUserSettingArgs = {
  input: AdminCompanyResetUserSettingsInputQl;
};


export type MutationAdminCreateCompanyArgs = {
  accessToPreScreening?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};


export type MutationAdminImpersonateUserArgs = {
  input: ImpersonateUserInputQl;
};


export type MutationAdminSendPasswordResetArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationAdminUpdateCompanyActiveArgs = {
  active: Scalars['Boolean']['input'];
  companyId: Scalars['Int']['input'];
};


export type MutationAdminUpdateCompanyNameArgs = {
  companyId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationAdminUpdateCompanyUsePreScreeningArgs = {
  companyId: Scalars['Int']['input'];
  preScreening: Scalars['Boolean']['input'];
};


export type MutationAdminUpdateUserArgs = {
  userId: Scalars['Int']['input'];
  userInput: UserUpdateInputQl;
};


export type MutationCreateUserApiTokenArgs = {
  input: CreateUserApiTokenInputQl;
};


export type MutationDeleteUserApiTokenArgs = {
  userApiTokenId: Scalars['Int']['input'];
};


export type MutationInvalidateAllSessionsArgs = {
  keepSessionToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginWithStatusArgs = {
  credentials: LoginInputQl;
};


export type MutationSendPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  repeatNewPassword: Scalars['String']['input'];
};


export type MutationUpdatePasswordFromResetTokenArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationUpdateUserLanguageArgs = {
  language: UserLanguage;
};


export type MutationUpdateUserSettingArgs = {
  input: UpdateUserSettingInputQl;
};

export type PaginationConfQl = {
  first?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type Query = {
  baseUserById: BaseUserQl;
  baseUsersInCurrentCompany: Array<BaseUserQl>;
  /** deprecated */
  companies: Array<UserCompanyQl>;
  /** deprecated */
  company?: Maybe<UserCompanyQl>;
  /** deprecated */
  currentAdmin: AdminQl;
  currentUser: SessionUserWithExtraAttribsQl;
  userApiTokens: Array<UserApiTokenQl>;
  userByUserApiToken?: Maybe<SessionUserQl>;
  /** deprecated */
  users: UsersResponseQl;
};


export type QueryBaseUserByIdArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryCompanyArgs = {
  companyId: Scalars['Int']['input'];
};


export type QueryUserByUserApiTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  queryParamsQl?: InputMaybe<QueryParamsQl>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  showInactive?: Scalars['Boolean']['input'];
};

export type QueryParamsQl = {
  pagination?: InputMaybe<PaginationConfQl>;
  /** Optional sorting to apply to the result set. */
  sorting?: InputMaybe<Array<SortingQl>>;
};

export type ResultQl = {
  ok: Scalars['Boolean']['output'];
};

export type SearchDataQl = {
  filterModules: Array<LinkedFilterModuleQl>;
};

export type SearchQl = {
  created: Scalars['DateTime']['output'];
  itemId: Scalars['Int']['output'];
  lastCheckedNewItemsDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  paused: Scalars['Boolean']['output'];
  searchData: SearchDataQl;
  type: SearchType;
  userCompanyId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export enum SearchType {
  Article = 'ARTICLE',
  Tender = 'TENDER'
}

export type SessionCompanyQl = {
  active: Scalars['Boolean']['output'];
  itemId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  settings: CompanySettingsQl;
};

export type SessionUserAuthenticationResultQl = {
  status: UserAuthenticationStatus;
  user?: Maybe<SessionUserWithExtraAttribsQl>;
};

export type SessionUserQl = {
  active: Scalars['Boolean']['output'];
  company: SessionCompanyQl;
  email: Scalars['String']['output'];
  featureToggles: Array<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  impersonatorInfo?: Maybe<ImpersonatorInfoQl>;
  isCsManager?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['Int']['output'];
  jobRoles: Array<UserJobRoles>;
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  loginMethod?: Maybe<LoginMethod>;
  roles: Array<Scalars['String']['output']>;
  searchOffset?: Maybe<Scalars['Int']['output']>;
  settings: UserSettingsQl;
};

export type SessionUserWithExtraAttribsQl = {
  active: Scalars['Boolean']['output'];
  colleagues: Array<ColleagueQl>;
  company: SessionCompanyQl;
  email: Scalars['String']['output'];
  featureToggles: Array<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  impersonatorInfo?: Maybe<ImpersonatorInfoQl>;
  isCsManager?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['Int']['output'];
  jobRoles: Array<UserJobRoles>;
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  loginMethod?: Maybe<LoginMethod>;
  roles: Array<Scalars['String']['output']>;
  searchOffset?: Maybe<Scalars['Int']['output']>;
  searches: Array<SearchQl>;
  settings: UserSettingsQl;
};

export enum SortingKey {
  SkDealAssigneeBid = 'SK_DEAL_ASSIGNEE_BID',
  SkDealClosestNextStepDate = 'SK_DEAL_CLOSEST_NEXT_STEP_DATE',
  SkDealCompletedDate = 'SK_DEAL_COMPLETED_DATE',
  SkDealCreatedDate = 'SK_DEAL_CREATED_DATE',
  SkDealCsvExportedDate = 'SK_DEAL_CSV_EXPORTED_DATE',
  SkDealCurrentStageId = 'SK_DEAL_CURRENT_STAGE_ID',
  SkDealExpectedCurrentStageConversionDate = 'SK_DEAL_EXPECTED_CURRENT_STAGE_CONVERSION_DATE',
  SkDealExpectedFinalConversionDate = 'SK_DEAL_EXPECTED_FINAL_CONVERSION_DATE',
  SkDealExpectedSalesPeriod = 'SK_DEAL_EXPECTED_SALES_PERIOD',
  SkDealOutreachCount = 'SK_DEAL_OUTREACH_COUNT',
  SkDealPipelineId = 'SK_DEAL_PIPELINE_ID',
  SkDealStageUpdatedDate = 'SK_DEAL_STAGE_UPDATED_DATE',
  SkDealState = 'SK_DEAL_STATE',
  SkDealTitle = 'SK_DEAL_TITLE',
  SkDealValueCurrencyNaive = 'SK_DEAL_VALUE_CURRENCY_NAIVE'
}

export enum SortingKeys {
  SkUsersActive = 'SK_USERS_ACTIVE',
  SkUsersCompanyId = 'SK_USERS_COMPANY_ID',
  SkUsersEmail = 'SK_USERS_EMAIL',
  SkUsersId = 'SK_USERS_ID',
  SkUsersLastLogin = 'SK_USERS_LAST_LOGIN',
  SkUsersName = 'SK_USERS_NAME'
}

export type SortingOpQl = {
  field: SortingKey;
  sop: SortingOperator;
};

export enum SortingOperator {
  SopAsc = 'SOP_ASC',
  SopDesc = 'SOP_DESC'
}

export enum SortingOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortingQl = {
  field: SortingKeys;
  order: SortingOrder;
};

export type UpdateUserSettingInputQl = {
  setting: UserSettingName;
  value: Scalars['JSON']['input'];
};

export type UserApiTokenQl = {
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  last4CharactersToken: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plainTextToken?: Maybe<Scalars['String']['output']>;
  prefix: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export enum UserAuthenticationStatus {
  InvalidCredentials = 'INVALID_CREDENTIALS',
  Success = 'SUCCESS',
  UserInactive = 'USER_INACTIVE'
}

export type UserCompanyQl = {
  active: Scalars['Boolean']['output'];
  colleagues: Array<ColleagueQl>;
  created: Scalars['DateTime']['output'];
  currentActiveNonBrUsers: Scalars['Int']['output'];
  itemId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  settings: CompanySettingsQl;
  statsUsersCountMeetingBookers?: Maybe<Scalars['Int']['output']>;
  statsUsersCountObservers?: Maybe<Scalars['Int']['output']>;
  statsUsersCountResearchers?: Maybe<Scalars['Int']['output']>;
  statsUsersCountSaleOwners?: Maybe<Scalars['Int']['output']>;
  statsUsersCountScreeners?: Maybe<Scalars['Int']['output']>;
  statsUsersCountSupervisors?: Maybe<Scalars['Int']['output']>;
  updated: Scalars['DateTime']['output'];
};

export enum UserJobRoles {
  MeetingBooker = 'MEETING_BOOKER',
  Observer = 'OBSERVER',
  Researcher = 'RESEARCHER',
  SaleOwner = 'SALE_OWNER',
  Screener = 'SCREENER',
  Supervisor = 'SUPERVISOR'
}

export enum UserLanguage {
  De = 'DE',
  En = 'EN'
}

export type UserQl = {
  active: Scalars['Boolean']['output'];
  company: UserCompanyQl;
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  featureToggles: Array<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  isCsManager?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['Int']['output'];
  jobRoles: Array<UserJobRoles>;
  language: Scalars['String']['output'];
  lastLogin: Scalars['DateTime']['output'];
  lastName: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
  settings: UserSettingsQl;
  token?: Maybe<Scalars['String']['output']>;
};

export enum UserSettingName {
  CsvDelimiter = 'CSV_DELIMITER',
  DefaultPipeline = 'DEFAULT_PIPELINE',
  EmailCommentMentions = 'EMAIL_COMMENT_MENTIONS',
  EmailDealAssigneeChange = 'EMAIL_DEAL_ASSIGNEE_CHANGE',
  EmailDigest = 'EMAIL_DIGEST',
  EmailPausedDealNotifications = 'EMAIL_PAUSED_DEAL_NOTIFICATIONS',
  EmailReminders = 'EMAIL_REMINDERS',
  OptInAchievements = 'OPT_IN_ACHIEVEMENTS',
  OutreachBlockIds = 'OUTREACH_BLOCK_IDS',
  OutreachCallBlockIds = 'OUTREACH_CALL_BLOCK_IDS',
  PipelineViewPreferencesAssignees = 'PIPELINE_VIEW_PREFERENCES_ASSIGNEES',
  PipelineViewPreferencesCustomParameterTag = 'PIPELINE_VIEW_PREFERENCES_CUSTOM_PARAMETER_TAG',
  PipelineViewPreferencesPaginationRows = 'PIPELINE_VIEW_PREFERENCES_PAGINATION_ROWS',
  PipelineViewPreferencesShowPausedDeals = 'PIPELINE_VIEW_PREFERENCES_SHOW_PAUSED_DEALS',
  PipelineViewPreferencesSorting = 'PIPELINE_VIEW_PREFERENCES_SORTING',
  PipelineViewPreferencesStageIds = 'PIPELINE_VIEW_PREFERENCES_STAGE_IDS',
  PipelineViewPreferencesVisualizationMode = 'PIPELINE_VIEW_PREFERENCES_VISUALIZATION_MODE'
}

export type UserSettingsQl = {
  csvDelimiter?: Maybe<Scalars['String']['output']>;
  defaultPipeline?: Maybe<Scalars['String']['output']>;
  emailCommentMentions?: Maybe<Scalars['Boolean']['output']>;
  /** Send emails when a deal is assigned to the user */
  emailDealAssigneeChange?: Maybe<Scalars['Boolean']['output']>;
  emailDigest?: Maybe<Scalars['String']['output']>;
  /** Send emails when a paused deal is ready to be reactivated */
  emailPausedDealNotifications?: Maybe<Scalars['Boolean']['output']>;
  emailReminders?: Maybe<Scalars['Boolean']['output']>;
  /** Opt-in achievements functionality */
  optInAchievements?: Maybe<Scalars['Boolean']['output']>;
  /** Saved outreach block ids for value proposition outreach action */
  outreachBlockIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Saved outreach block ids for value proposition outreach action call script */
  outreachCallBlockIds?: Maybe<Array<Scalars['String']['output']>>;
  /** List of assignees to filter by in pipeline view */
  pipelineViewPreferencesAssignees?: Maybe<Array<Scalars['Int']['output']>>;
  /** Dict with custom parameter tags with a list of options to filter by in pipeline view */
  pipelineViewPreferencesCustomParameterTag?: Maybe<Scalars['JSON']['output']>;
  /** Number of rows to show in pipeline view */
  pipelineViewPreferencesPaginationRows?: Maybe<Scalars['Int']['output']>;
  /** Show paused deals in pipeline view */
  pipelineViewPreferencesShowPausedDeals?: Maybe<Scalars['Boolean']['output']>;
  /** List of sorting preferences in pipeline view */
  pipelineViewPreferencesSorting?: Maybe<Array<SortingOpQl>>;
  /** List of stage ids to filter by in pipeline view */
  pipelineViewPreferencesStageIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Visualization mode: 'grid' or 'table' */
  pipelineViewPreferencesVisualizationMode?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateInputQl = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  inboxOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UsersResponseQl = {
  total: Scalars['Int']['output'];
  users: Array<UserQl>;
};

export type UserPartFragment = { active: boolean, email: string, featureToggles: Array<string>, firstName: string, isCsManager?: boolean | null, language: string, itemId: number, roles: Array<string>, lastName: string, searchOffset?: number | null, loginMethod?: LoginMethod | null, jobRoles: Array<UserJobRoles>, impersonatorInfo?: { itemId: number, email: string } | null, colleagues: Array<{ roles: Array<string>, lastName: string, itemId: number, firstName: string, email: string, active: boolean }>, company: { name: string, itemId: number, settings: { currency?: Currency | null, merging?: boolean | null, preScreening?: boolean | null, useAppDotBr?: boolean | null } }, settings: { defaultPipeline?: string | null, csvDelimiter?: string | null, emailCommentMentions?: boolean | null, emailDigest?: string | null, emailPausedDealNotifications?: boolean | null, emailReminders?: boolean | null, emailDealAssigneeChange?: boolean | null, pipelineViewPreferencesAssignees?: Array<number> | null, pipelineViewPreferencesCustomParameterTag?: any | null, pipelineViewPreferencesShowPausedDeals?: boolean | null, pipelineViewPreferencesVisualizationMode?: string | null, pipelineViewPreferencesPaginationRows?: number | null, pipelineViewPreferencesStageIds?: Array<string> | null, optInAchievements?: boolean | null, outreachBlockIds?: Array<string> | null, outreachCallBlockIds?: Array<string> | null, pipelineViewPreferencesSorting?: Array<{ field: SortingKey, sop: SortingOperator }> | null }, searches: Array<{ itemId: number, name: string, type: SearchType, userId: number, searchData: { filterModules: Array<{ filter: string, key: number, value: string }> } }> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { currentUser: { active: boolean, email: string, featureToggles: Array<string>, firstName: string, isCsManager?: boolean | null, language: string, itemId: number, roles: Array<string>, lastName: string, searchOffset?: number | null, loginMethod?: LoginMethod | null, jobRoles: Array<UserJobRoles>, impersonatorInfo?: { itemId: number, email: string } | null, colleagues: Array<{ roles: Array<string>, lastName: string, itemId: number, firstName: string, email: string, active: boolean }>, company: { name: string, itemId: number, settings: { currency?: Currency | null, merging?: boolean | null, preScreening?: boolean | null, useAppDotBr?: boolean | null } }, settings: { defaultPipeline?: string | null, csvDelimiter?: string | null, emailCommentMentions?: boolean | null, emailDigest?: string | null, emailPausedDealNotifications?: boolean | null, emailReminders?: boolean | null, emailDealAssigneeChange?: boolean | null, pipelineViewPreferencesAssignees?: Array<number> | null, pipelineViewPreferencesCustomParameterTag?: any | null, pipelineViewPreferencesShowPausedDeals?: boolean | null, pipelineViewPreferencesVisualizationMode?: string | null, pipelineViewPreferencesPaginationRows?: number | null, pipelineViewPreferencesStageIds?: Array<string> | null, optInAchievements?: boolean | null, outreachBlockIds?: Array<string> | null, outreachCallBlockIds?: Array<string> | null, pipelineViewPreferencesSorting?: Array<{ field: SortingKey, sop: SortingOperator }> | null }, searches: Array<{ itemId: number, name: string, type: SearchType, userId: number, searchData: { filterModules: Array<{ filter: string, key: number, value: string }> } }> } };

export type GetUserApiTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserApiTokensQuery = { userApiTokens: Array<{ created: any, id: number, last4CharactersToken: string, name: string, plainTextToken?: string | null, userId: number, updated: any, prefix: string }> };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { loginWithStatus: { status: UserAuthenticationStatus, user?: { active: boolean, email: string, featureToggles: Array<string>, firstName: string, isCsManager?: boolean | null, language: string, itemId: number, roles: Array<string>, lastName: string, searchOffset?: number | null, loginMethod?: LoginMethod | null, jobRoles: Array<UserJobRoles>, impersonatorInfo?: { itemId: number, email: string } | null, colleagues: Array<{ roles: Array<string>, lastName: string, itemId: number, firstName: string, email: string, active: boolean }>, company: { name: string, itemId: number, settings: { currency?: Currency | null, merging?: boolean | null, preScreening?: boolean | null, useAppDotBr?: boolean | null } }, settings: { defaultPipeline?: string | null, csvDelimiter?: string | null, emailCommentMentions?: boolean | null, emailDigest?: string | null, emailPausedDealNotifications?: boolean | null, emailReminders?: boolean | null, emailDealAssigneeChange?: boolean | null, pipelineViewPreferencesAssignees?: Array<number> | null, pipelineViewPreferencesCustomParameterTag?: any | null, pipelineViewPreferencesShowPausedDeals?: boolean | null, pipelineViewPreferencesVisualizationMode?: string | null, pipelineViewPreferencesPaginationRows?: number | null, pipelineViewPreferencesStageIds?: Array<string> | null, optInAchievements?: boolean | null, outreachBlockIds?: Array<string> | null, outreachCallBlockIds?: Array<string> | null, pipelineViewPreferencesSorting?: Array<{ field: SortingKey, sop: SortingOperator }> | null }, searches: Array<{ itemId: number, name: string, type: SearchType, userId: number, searchData: { filterModules: Array<{ filter: string, key: number, value: string }> } }> } | null } };

export type ImpersonateUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ImpersonateUserMutation = { adminImpersonateUser: { user: { active: boolean, email: string, featureToggles: Array<string>, firstName: string, isCsManager?: boolean | null, language: string, itemId: number, roles: Array<string>, lastName: string, searchOffset?: number | null, loginMethod?: LoginMethod | null, jobRoles: Array<UserJobRoles>, impersonatorInfo?: { itemId: number, email: string } | null, colleagues: Array<{ roles: Array<string>, lastName: string, itemId: number, firstName: string, email: string, active: boolean }>, company: { name: string, itemId: number, settings: { currency?: Currency | null, merging?: boolean | null, preScreening?: boolean | null, useAppDotBr?: boolean | null } }, settings: { defaultPipeline?: string | null, csvDelimiter?: string | null, emailCommentMentions?: boolean | null, emailDigest?: string | null, emailPausedDealNotifications?: boolean | null, emailReminders?: boolean | null, emailDealAssigneeChange?: boolean | null, pipelineViewPreferencesAssignees?: Array<number> | null, pipelineViewPreferencesCustomParameterTag?: any | null, pipelineViewPreferencesShowPausedDeals?: boolean | null, pipelineViewPreferencesVisualizationMode?: string | null, pipelineViewPreferencesPaginationRows?: number | null, pipelineViewPreferencesStageIds?: Array<string> | null, optInAchievements?: boolean | null, outreachBlockIds?: Array<string> | null, outreachCallBlockIds?: Array<string> | null, pipelineViewPreferencesSorting?: Array<{ field: SortingKey, sop: SortingOperator }> | null }, searches: Array<{ itemId: number, name: string, type: SearchType, userId: number, searchData: { filterModules: Array<{ filter: string, key: number, value: string }> } }> } } };

export type CreateUserApiTokenMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateUserApiTokenMutation = { createUserApiToken: { created: any, id: number, last4CharactersToken: string, name: string, plainTextToken?: string | null, updated: any, userId: number, prefix: string } };

export type DeleteUserApiTokenMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteUserApiTokenMutation = { deleteUserApiToken: { ok: boolean } };

export type UpdateUserSettingMutationVariables = Exact<{
  setting: UserSettingName;
  value: Scalars['JSON']['input'];
}>;


export type UpdateUserSettingMutation = { updateUserSetting: { ok: boolean } };

export type UpdateUserLanguageMutationVariables = Exact<{
  language: UserLanguage;
}>;


export type UpdateUserLanguageMutation = { updateUserLanguage: { ok: boolean } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout: { ok: boolean } };

export type SendPasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendPasswordResetMutation = { sendPasswordReset: { ok: boolean } };

export type SetXsrfTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type SetXsrfTokenMutation = { setXsrfToken: { ok: boolean } };

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  repeatNewPassword: Scalars['String']['input'];
}>;


export type UpdatePasswordMutation = { updatePassword: { ok: boolean } };

export type UpdatePasswordFromResetTokenMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type UpdatePasswordFromResetTokenMutation = { updatePasswordFromResetToken: { ok: boolean } };

export const UserPartFragmentDoc = gql`
    fragment UserPart on SessionUserWithExtraAttribsQL {
  active
  email
  featureToggles
  firstName
  isCsManager
  language
  itemId
  roles
  lastName
  searchOffset
  loginMethod
  jobRoles
  impersonatorInfo {
    itemId
    email
  }
  colleagues {
    roles
    lastName
    itemId
    firstName
    email
    active
  }
  company {
    name
    itemId
    settings {
      currency
      merging
      preScreening
      useAppDotBr
    }
  }
  settings {
    defaultPipeline
    csvDelimiter
    emailCommentMentions
    emailDigest
    emailPausedDealNotifications
    emailReminders
    emailDealAssigneeChange
    pipelineViewPreferencesAssignees
    pipelineViewPreferencesCustomParameterTag
    pipelineViewPreferencesShowPausedDeals
    pipelineViewPreferencesVisualizationMode
    pipelineViewPreferencesPaginationRows
    pipelineViewPreferencesSorting {
      field
      sop
    }
    pipelineViewPreferencesStageIds
    optInAchievements
    outreachBlockIds
    outreachCallBlockIds
  }
  searches {
    itemId
    name
    searchData {
      filterModules {
        filter
        key
        value
      }
    }
    type
    userId
  }
}
    `;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    ...UserPart
  }
}
    ${UserPartFragmentDoc}`;
export const GetUserApiTokensDocument = gql`
    query GetUserApiTokens {
  userApiTokens {
    created
    id
    last4CharactersToken
    name
    plainTextToken
    userId
    updated
    prefix
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  loginWithStatus(credentials: {email: $email, password: $password}) {
    status
    user {
      ...UserPart
    }
  }
}
    ${UserPartFragmentDoc}`;
export const ImpersonateUserDocument = gql`
    mutation ImpersonateUser($email: String!) {
  adminImpersonateUser(input: {email: $email}) {
    user {
      ...UserPart
    }
  }
}
    ${UserPartFragmentDoc}`;
export const CreateUserApiTokenDocument = gql`
    mutation CreateUserApiToken($name: String!) {
  createUserApiToken(input: {name: $name}) {
    created
    id
    last4CharactersToken
    name
    plainTextToken
    updated
    userId
    prefix
  }
}
    `;
export const DeleteUserApiTokenDocument = gql`
    mutation DeleteUserApiToken($id: Int!) {
  deleteUserApiToken(userApiTokenId: $id) {
    ok
  }
}
    `;
export const UpdateUserSettingDocument = gql`
    mutation updateUserSetting($setting: UserSettingName!, $value: JSON!) {
  updateUserSetting(input: {setting: $setting, value: $value}) {
    ok
  }
}
    `;
export const UpdateUserLanguageDocument = gql`
    mutation UpdateUserLanguage($language: UserLanguage!) {
  updateUserLanguage(language: $language) {
    ok
  }
}
    `;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    ok
  }
}
    `;
export const SendPasswordResetDocument = gql`
    mutation SendPasswordReset($email: String!) {
  sendPasswordReset(email: $email) {
    ok
  }
}
    `;
export const SetXsrfTokenDocument = gql`
    mutation SetXSRFToken {
  setXsrfToken {
    ok
  }
}
    `;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($oldPassword: String!, $newPassword: String!, $repeatNewPassword: String!) {
  updatePassword(
    oldPassword: $oldPassword
    newPassword: $newPassword
    repeatNewPassword: $repeatNewPassword
  ) {
    ok
  }
}
    `;
export const UpdatePasswordFromResetTokenDocument = gql`
    mutation UpdatePasswordFromResetToken($newPassword: String!, $token: String!) {
  updatePasswordFromResetToken(newPassword: $newPassword, token: $token) {
    ok
  }
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    GetCurrentUser(variables?: GetCurrentUserQueryVariables, options?: C): Promise<GetCurrentUserQuery> {
      return requester<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, variables, options) as Promise<GetCurrentUserQuery>;
    },
    GetUserApiTokens(variables?: GetUserApiTokensQueryVariables, options?: C): Promise<GetUserApiTokensQuery> {
      return requester<GetUserApiTokensQuery, GetUserApiTokensQueryVariables>(GetUserApiTokensDocument, variables, options) as Promise<GetUserApiTokensQuery>;
    },
    Login(variables: LoginMutationVariables, options?: C): Promise<LoginMutation> {
      return requester<LoginMutation, LoginMutationVariables>(LoginDocument, variables, options) as Promise<LoginMutation>;
    },
    ImpersonateUser(variables: ImpersonateUserMutationVariables, options?: C): Promise<ImpersonateUserMutation> {
      return requester<ImpersonateUserMutation, ImpersonateUserMutationVariables>(ImpersonateUserDocument, variables, options) as Promise<ImpersonateUserMutation>;
    },
    CreateUserApiToken(variables: CreateUserApiTokenMutationVariables, options?: C): Promise<CreateUserApiTokenMutation> {
      return requester<CreateUserApiTokenMutation, CreateUserApiTokenMutationVariables>(CreateUserApiTokenDocument, variables, options) as Promise<CreateUserApiTokenMutation>;
    },
    DeleteUserApiToken(variables: DeleteUserApiTokenMutationVariables, options?: C): Promise<DeleteUserApiTokenMutation> {
      return requester<DeleteUserApiTokenMutation, DeleteUserApiTokenMutationVariables>(DeleteUserApiTokenDocument, variables, options) as Promise<DeleteUserApiTokenMutation>;
    },
    updateUserSetting(variables: UpdateUserSettingMutationVariables, options?: C): Promise<UpdateUserSettingMutation> {
      return requester<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>(UpdateUserSettingDocument, variables, options) as Promise<UpdateUserSettingMutation>;
    },
    UpdateUserLanguage(variables: UpdateUserLanguageMutationVariables, options?: C): Promise<UpdateUserLanguageMutation> {
      return requester<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument, variables, options) as Promise<UpdateUserLanguageMutation>;
    },
    Logout(variables?: LogoutMutationVariables, options?: C): Promise<LogoutMutation> {
      return requester<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables, options) as Promise<LogoutMutation>;
    },
    SendPasswordReset(variables: SendPasswordResetMutationVariables, options?: C): Promise<SendPasswordResetMutation> {
      return requester<SendPasswordResetMutation, SendPasswordResetMutationVariables>(SendPasswordResetDocument, variables, options) as Promise<SendPasswordResetMutation>;
    },
    SetXSRFToken(variables?: SetXsrfTokenMutationVariables, options?: C): Promise<SetXsrfTokenMutation> {
      return requester<SetXsrfTokenMutation, SetXsrfTokenMutationVariables>(SetXsrfTokenDocument, variables, options) as Promise<SetXsrfTokenMutation>;
    },
    UpdatePassword(variables: UpdatePasswordMutationVariables, options?: C): Promise<UpdatePasswordMutation> {
      return requester<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, variables, options) as Promise<UpdatePasswordMutation>;
    },
    UpdatePasswordFromResetToken(variables: UpdatePasswordFromResetTokenMutationVariables, options?: C): Promise<UpdatePasswordFromResetTokenMutation> {
      return requester<UpdatePasswordFromResetTokenMutation, UpdatePasswordFromResetTokenMutationVariables>(UpdatePasswordFromResetTokenDocument, variables, options) as Promise<UpdatePasswordFromResetTokenMutation>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;