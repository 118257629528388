import {
    DealActivity,
    DealActivityType,
    ActivityLogSubType,
} from 'src/app-features/deal-activity/domain/deal-activity.model';
import { AbortParams } from 'src/data/api/api-client';
import {
    QueryParamsQl,
    FilterOpQl,
    FilterOperators,
    FilterKeys,
    DealState as RawDealState,
    ObjectiveUpdateInputQl,
    DealAttribute,
    DealCreationSource,
    ActivityLogType,
    ActivityLogSubType as ActivityLogSubTypeSdk,
    ProjectDataFromCustomTextQl,
    ProjectDataFromSearchResultQl,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { CurrencyValue } from 'src/domain/models/currency-value/currency-value.model';
import {
    Alert,
    createActivityLog,
    createDeal,
    createDealAlert,
    createMinimalDeal,
    createPipelineDeal,
    Deal,
    DealState,
    MinimalDeal,
    PipelineDeal,
    StateModelToRaw,
} from 'src/domain/models/deal/deal.model';
import {
    createObjective,
    Objective,
} from 'src/domain/models/objective/objective.model';
import {
    SuccessState,
    SuccessStateModelToRaw,
} from 'src/domain/models/success-state/success-state.model';

interface RequestByDealIdParams extends AbortParams {
    id: string;
}
interface GetDealsByPipelineParams extends AbortParams {
    options?: QueryParamsQl;
}

interface GetMinimalDealsParams extends AbortParams {
    state: DealState;
    options?: QueryParamsQl;
}

interface GetMinimalDealsByUserParams extends AbortParams {
    userId: string;
    options?: QueryParamsQl;
}

interface GetDealByIdParams extends AbortParams {
    id: string;
}

interface MoveDealToStageParams extends AbortParams {
    dealId: string;
    stageId: string;
}

interface DisqualifyDealParams extends AbortParams {
    dealId: string;
}

interface RestoreDealParams extends AbortParams {
    dealId: string;
}

interface MarkDealAsDoneParams extends AbortParams {
    dealId: string;
}

interface CreateNewDealParams extends AbortParams {
    pipelineId: string;
    projectId?: string;
    title: string;
    searchNames?: string[];
    creationSource: DealCreationSource;
    articleId?: string;
    projectDataFromCustomText?: ProjectDataFromCustomTextQl;
    projectDataFromSearchResult?: ProjectDataFromSearchResultQl;
}

interface BulkUpdateDealAttributesParams extends AbortParams {
    attribute: DealAttribute;
    dealIds: string[];
    value: number | string;
}

interface EditDealInformationParams extends AbortParams {
    dealId: string;
    note: string;
    dealRevenue: CurrencyValue;
}

interface EditDealTitleParams extends AbortParams {
    dealId: string;
    dealTitle: string;
}

interface UpdateObjectiveParams extends AbortParams {
    objectiveId: string;
    update: {
        state?: SuccessState;
        status?: string;
    };
}

interface DealUpdateMutationData {
    dealValue?: CurrencyValue;
    notes?: string;
    assignee?: number;
}

interface AssignUserToDealParams extends AbortParams {
    dealId: string;
    assigneeId: number;
}

interface UpdateDealsPipelineAndStage extends AbortParams {
    dealIds: string[];
    targetPipelineId: string;
    targetStageId: string;
    targetDealState: DealState;
    unpauseDeals: boolean | undefined;
}

interface UpdateDealsPipelineAndStageResponse {
    ok: boolean;
    dealIds: string[];
}

interface PauseDealParams extends AbortParams {
    dealId: string;
    pausedUntil: Date;
}

interface UnPauseDealParams extends AbortParams {
    dealId: string;
}
/**
 * TODO: NEW
 * Holds the response for getDealsByPipeline, containing the
 * fetched list of deals + pagination information
 */
export interface PipelineDealCollection {
    deals: PipelineDeal[];
    totalCount: number;
}

export interface MinimalDealsCollection {
    minimalDeals: MinimalDeal[];
    totalCount: number;
    hasNextPage: boolean;
    elementsOnPageCount: number;
}

interface CreateActivityLogParams extends AbortParams {
    dealId: string;
    type: DealActivityType;
    notes?: string;
    contactId?: string;
    subType: ActivityLogSubType;
    customType?: string;
}
export interface DealsApi {
    getDealObjectives: (params: GetDealByIdParams) => Promise<Objective[]>;
    getDealStageUpdateDate: (params: GetDealByIdParams) => Promise<string>;
    getMinimalDeals: (
        params: GetDealsByPipelineParams,
    ) => Promise<MinimalDealsCollection>;
    getDealsByState: (params: GetMinimalDealsParams) => Promise<MinimalDeal[]>;
    getDealsByUser: (
        params: GetMinimalDealsByUserParams,
    ) => Promise<MinimalDeal[]>;
    moveDealToStage: (params: MoveDealToStageParams) => Promise<boolean>;
    createNewDeal: (params: CreateNewDealParams) => Promise<string | null>;
    editDealInformation: (
        params: EditDealInformationParams,
    ) => Promise<boolean>;
    editDealTitle: (params: EditDealTitleParams) => Promise<boolean>;
    updateObjective: (params: UpdateObjectiveParams) => Promise<boolean>;
    assignUserToDeal: (params: AssignUserToDealParams) => Promise<boolean>;
    getDealById: (
        params: GetDealByIdParams,
    ) => Promise<{ deal: Deal | undefined; objectives: Objective[] }>;
    disqualifyDeal: (params: DisqualifyDealParams) => Promise<Deal | undefined>;
    restoreDeal: (params: RestoreDealParams) => Promise<Deal | undefined>;
    markDealAsDone: (params: MarkDealAsDoneParams) => Promise<Deal | undefined>;
    updateDealsPipelineAndStage: (
        params: UpdateDealsPipelineAndStage,
    ) => Promise<UpdateDealsPipelineAndStageResponse>;
    getDealsCount: () => Promise<number>;
    pauseDeal: (params: PauseDealParams) => Promise<boolean>;
    unPauseDeal: (params: UnPauseDealParams) => Promise<boolean>;
    getDealAlert: (params: RequestByDealIdParams) => Promise<Alert>;
    getDealsByPipeline: (
        params: GetDealsByPipelineParams,
    ) => Promise<PipelineDealCollection>;
    bulkUpdateDealAttributes: (
        params: BulkUpdateDealAttributesParams,
    ) => Promise<string[]>;
    createDealActivity: (
        params: CreateActivityLogParams,
    ) => Promise<DealActivity>;
}

export const createDealsApi = (processGqlSdk: ProcessGqlSdk): DealsApi => {
    const getMinimalDeals = async (
        params: GetDealsByPipelineParams,
    ): Promise<MinimalDealsCollection> => {
        const { options } = params;
        const queryParams = { ...options };
        const response = await processGqlSdk.GetAllDealsCollection({
            queryParams,
        });
        return {
            minimalDeals:
                (response.dealsCollection.edges ?? []).map((edge) =>
                    createMinimalDeal(edge.node),
                ) ?? [],
            totalCount: response.dealsCollection.totalCount,
            hasNextPage: response.dealsCollection.pageInfo.hasNextPage,
            elementsOnPageCount: response.dealsCollection.pageInfo.count,
        };
    };

    const getDealsByState = async (
        params: GetMinimalDealsParams,
    ): Promise<MinimalDeal[]> => {
        const { state, options } = params;
        const rawDealState = StateModelToRaw[state];
        const defaultFilteringByPipeLine: FilterOpQl = {
            field: FilterKeys.FkDealState,
            fop: FilterOperators.FopEq,
            value: rawDealState,
        };

        const queryParams = { ...options };
        if (!queryParams.filtering) {
            queryParams.filtering = [defaultFilteringByPipeLine];
        } else {
            queryParams.filtering = [
                ...queryParams.filtering,
                defaultFilteringByPipeLine,
            ];
        }
        const response = await processGqlSdk.GetAllDeals({ queryParams });
        return response.allDeals.map(createMinimalDeal);
    };

    const getDealsByUser = async (
        params: GetMinimalDealsByUserParams,
    ): Promise<MinimalDeal[]> => {
        const { userId, options } = params;
        const defaultFilteringByPipeLine: FilterOpQl[] = [
            {
                field: FilterKeys.FkDealAssigneeBid,
                fop: FilterOperators.FopEq,
                value: userId,
            },
            {
                field: FilterKeys.FkDealState,
                fop: FilterOperators.FopEq,
                value: RawDealState.InPlay,
            },
        ];

        const queryParams = { ...options };
        if (!queryParams.filtering) {
            queryParams.filtering = defaultFilteringByPipeLine;
        } else {
            queryParams.filtering = [
                ...queryParams.filtering,
                ...defaultFilteringByPipeLine,
            ];
        }
        const response = await processGqlSdk.GetAllDeals({ queryParams });
        return response.allDeals.map(createMinimalDeal);
    };

    const getDealById = async (
        params: GetDealByIdParams,
    ): Promise<{ deal: Deal | undefined; objectives: Objective[] }> => {
        const response = await processGqlSdk.GetDeal({ dealId: params.id });
        const deal = response?.deal ? createDeal(response.deal) : undefined;
        const objectives = (response?.deal?.currentObjectives ?? []).map(
            createObjective,
        );
        return { deal, objectives };
    };

    const getDealObjectives = async (
        params: GetDealByIdParams,
    ): Promise<Objective[]> => {
        const response = await processGqlSdk.GetDealObjectives({
            dealId: params.id,
        });
        return response.deal.currentObjectives.map(createObjective);
    };

    const getDealStageUpdateDate = async (
        params: GetDealByIdParams,
    ): Promise<string> => {
        const response = await processGqlSdk.GetDealStageUpdateDate({
            dealId: params.id,
        });
        return response.deal.stageUpdatedDate;
    };

    const moveDealToStage = async (
        params: MoveDealToStageParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.MoveDealToStage(params);
        return response.moveToStage.ok ?? false;
    };

    const disqualifyDeal = async (
        params: DisqualifyDealParams,
    ): Promise<Deal | undefined> => {
        const response = await processGqlSdk.DisqualifyDeal({
            dealId: params.dealId,
        });

        return response.disqualifyDeal
            ? createDeal(response.disqualifyDeal)
            : undefined;
    };

    const restoreDeal = async (
        params: RestoreDealParams,
    ): Promise<Deal | undefined> => {
        const response = await processGqlSdk.RestoreDeal({
            dealId: params.dealId,
        });

        return response.restoreDeal
            ? createDeal(response.restoreDeal)
            : undefined;
    };

    const markDealAsDone = async (
        params: MarkDealAsDoneParams,
    ): Promise<Deal | undefined> => {
        const response = await processGqlSdk.MarkDealAsDone({
            dealId: params.dealId,
        });

        return response.markDealAsDone
            ? createDeal(response.markDealAsDone)
            : undefined;
    };

    const createNewDeal = async (
        params: CreateNewDealParams,
    ): Promise<string | null> => {
        const response = await processGqlSdk.CreateNewDealMutation(params);
        return response.createDeal.ok ? response.createDeal.dealId : null;
    };

    const editDealInformation = async (
        params: EditDealInformationParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.UpdateDealInformation({
            dealId: params.dealId,
            updateDeal: {
                notes: params.note,
                dealValue: {
                    value: params.dealRevenue.value,
                    currency: params.dealRevenue.currency,
                },
            },
        });
        return response.updateDeal.ok ?? false;
    };

    const bulkUpdateDealAttributes = async (
        params: BulkUpdateDealAttributesParams,
    ): Promise<string[]> => {
        const { attribute, dealIds, value } = params;
        const response = await processGqlSdk.BulkUpdateDealAttributes({
            dealIds,
            attribute,
            value,
        });
        return response.bulkUpdateDealAttributes.updatedDealIds;
    };

    const editDealTitle = async (
        params: EditDealTitleParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.UpdateDealInformation({
            dealId: params.dealId,
            updateDeal: { title: params.dealTitle },
        });
        return response.updateDeal.ok ?? false;
    };

    const updateObjective = async (
        params: UpdateObjectiveParams,
    ): Promise<boolean> => {
        const { objectiveId, update } = params;
        const updateObjective: ObjectiveUpdateInputQl = {};
        if (update.state) {
            updateObjective.state = SuccessStateModelToRaw[update.state];
        }
        updateObjective.status = update.status;
        const response = await processGqlSdk.UpdateObjectiveMutation({
            objectiveId,
            updateObjective,
        });
        return response.updateObjective.ok ?? false;
    };

    const assignUserToDeal = async (
        params: AssignUserToDealParams,
    ): Promise<boolean> => {
        const updateData: DealUpdateMutationData = {
            assignee: params.assigneeId,
        };
        const response = await processGqlSdk.AssignUserToDeal({
            dealId: params.dealId,
            update: updateData,
        });
        return response.updateDeal.ok ?? false;
    };

    const updateDealsPipelineAndStage = async (
        params: UpdateDealsPipelineAndStage,
    ): Promise<UpdateDealsPipelineAndStageResponse> => {
        const response = await processGqlSdk.BulkMove({
            dealIds: params.dealIds,
            targetDealState: StateModelToRaw[params.targetDealState],
            targetPipelineId: params.targetPipelineId,
            targetStageId: params.targetStageId,
            unpauseDeals: params.unpauseDeals,
        });
        return {
            ok: response.bulkMove.ok ?? false,
            dealIds: response.bulkMove.updatedDealIds,
        };
    };

    const getDealsCount = async (): Promise<number> => {
        const response = await processGqlSdk.GetDealsCount();
        return response.dealsCollection.totalCount ?? 0;
    };

    const pauseDeal = async (params: PauseDealParams): Promise<boolean> => {
        const response = await processGqlSdk.PauseDeal({
            dealId: params.dealId,
            pausedUntil: params.pausedUntil.toISOString(),
        });
        return response.pauseDeal.ok ?? false;
    };

    const unPauseDeal = async (params: UnPauseDealParams): Promise<boolean> => {
        const response = await processGqlSdk.UnPauseDeal({
            dealId: params.dealId,
        });
        return response.unpauseDeal.ok ?? false;
    };

    const getDealAlert = async ({
        id,
    }: RequestByDealIdParams): Promise<Alert> => {
        const response = await processGqlSdk.GetDealAlert({ dealId: id });

        return createDealAlert(response.deal.alert);
    };

    /**
     * TODO: NEW
     * New api function to fetch deals for a pipeline
     */
    const getDealsByPipeline = async (
        params: GetDealsByPipelineParams,
    ): Promise<PipelineDealCollection> => {
        const queryParams = { ...params.options };
        const response = await processGqlSdk.GetDealsCollectionByPipeline({
            queryParams,
        });

        return {
            deals:
                (response.dealsCollection.edges ?? []).map((edge) =>
                    createPipelineDeal(edge.node),
                ) ?? [],
            totalCount: response.dealsCollection.totalCount,
        };
    };

    const createDealActivity = async (
        params: CreateActivityLogParams,
    ): Promise<DealActivity> => {
        const response = await processGqlSdk.CreateDealActivity({
            activityType: params.type.toString() as ActivityLogType,
            subType: params.subType.toString() as ActivityLogSubTypeSdk,
            customType: params.customType,
            dealId: params.dealId,
            contactId: params.contactId,
            notes: params.notes,
        });
        return createActivityLog(response.createActivityLog);
    };
    return {
        getDealObjectives,
        getDealStageUpdateDate,
        getMinimalDeals,
        getDealsByState,
        getDealsByUser,
        moveDealToStage,
        editDealInformation,
        editDealTitle,
        createNewDeal,
        updateObjective,
        assignUserToDeal,
        getDealById,
        disqualifyDeal,
        restoreDeal,
        markDealAsDone,
        updateDealsPipelineAndStage,
        getDealsCount,
        pauseDeal,
        unPauseDeal,
        getDealAlert,
        getDealsByPipeline,
        bulkUpdateDealAttributes,
        createDealActivity,
    };
};
