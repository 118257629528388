import { observer } from 'mobx-react';
import { usePostHog } from 'posthog-js/react';
import { FC, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';

import { setCurrentLoggedSentryUser } from 'src/sentry/instrument';
import {
    AccountType,
    LicenseType,
} from 'src/domain/models/company/company.model';
import { User } from 'src/domain/models/user/user.model';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { SecondaryNavbarLayout } from 'src/presentation/shared/secondary-navbar-layout/secondary-navbar-layout.component';
import { withFeatures } from 'src/utils/component.utils';
import { createCtx } from 'src/utils/context.utils';
import { IChildren } from 'src/utils/react.utils';
import { isDevEnv } from 'src/utils/environment.utils';

const [useUser, UserProvider] = createCtx<User>();
export default useUser;

interface UserProviderComponentProps extends IChildren {
    loginWithExistedUser: () => void;
    setPreviousPathname: (pathname: string | null) => void;
    fetchAccountConfigurations: () => void;
    getAchievements: () => void;
    hasUnauthorizedError: boolean;
    isLoading: boolean;
    isAuthorized: boolean;
    user: User | undefined;
    accountConfigurationsInitialized: boolean;
}

const UserProviderComponent: FC<UserProviderComponentProps> = observer(
    ({
        loginWithExistedUser,
        setPreviousPathname,
        fetchAccountConfigurations,
        getAchievements,
        hasUnauthorizedError,
        isLoading,
        isAuthorized,
        user,
        accountConfigurationsInitialized,
        children,
    }) => {
        const location = useLocation();
        const posthog = usePostHog();

        useEffect(() => {
            if (!isAuthorized) {
                loginWithExistedUser();
            }
        }, [loginWithExistedUser, isAuthorized]);

        useEffect(() => {
            if (user) {
                fetchAccountConfigurations();
                getAchievements();
                setCurrentLoggedSentryUser(user);
            }
        }, [fetchAccountConfigurations, getAchievements, user]);

        useEffect(() => {
            const shouldRecordForCurrentUser =
                (user &&
                    user.companySettings.accountType ===
                        AccountType.Production &&
                    user.companySettings.licenseType !== LicenseType.Other &&
                    !user.isImpersonated) ||
                (user && isDevEnv);

            if (shouldRecordForCurrentUser && posthog) {
                posthog.identify(user.itemId.toString());
                posthog.setPersonProperties({
                    userId: user.itemId,
                    email: user.email,
                });

                posthog.startSessionRecording();
            }
        }, [posthog, user]);

        if (isLoading) {
            return <SecondaryNavbarLayout title="common.loading" />;
        }

        if (hasUnauthorizedError) {
            const pathname =
                location.pathname + location.search + location.hash;
            setPreviousPathname(pathname);
            return (
                <Redirect
                    exact
                    strict
                    to={{ pathname: AppRoute.login, search: location.search }}
                />
            );
        }

        if (!isAuthorized || !user || !accountConfigurationsInitialized) {
            return <SecondaryNavbarLayout title="common.loading" />;
        }

        return <UserProvider value={user}>{children}</UserProvider>;
    },
);

export const UserProviderContainer = withFeatures(UserProviderComponent)(
    (f) => ({
        loginWithExistedUser: f.authFeature.loginWithExistingUser,
        setPreviousPathname: f.authFeature.setPreviousPathname,
        fetchAccountConfigurations:
            f.accountConfigurationFeature.fetchAccountConfigurations,
        getAchievements: f.achievementsFeature.getAchievements,
        hasUnauthorizedError: f.authFeature.hasUnauthorizedError,
        isLoading: f.authFeature.isLoading,
        isAuthorized: f.authFeature.isAuthorized,
        user: f.authFeature.user,
        accountConfigurationsInitialized:
            f.accountConfigurationFeature.accountConfigurationsInitialized,
    }),
);
