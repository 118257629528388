import { BrIcon, IconName } from '@buildingradar/br_component_lib';

import { FolderType } from 'src/domain/models/folder/folder.model';

export const getFolderIcon = (
    isActive: boolean,
    folder: FolderType,
    newScreeningIconEnabled?: boolean,
): JSX.Element => {
    const activeIconColor = 'var(--blue-60)';

    if (folder === FolderType.inbox) {
        return isActive ? (
            <BrIcon
                iconName={
                    newScreeningIconEnabled
                        ? IconName.BrInboxSearchIcon
                        : IconName.BrInboxIcon
                }
                iconColor={activeIconColor}
                iconSize={30}
            />
        ) : (
            <BrIcon
                iconName={
                    newScreeningIconEnabled
                        ? IconName.BrInboxSearchIcon
                        : IconName.BrInboxOutlineIcon
                }
                iconSize={30}
            />
        );
    }
    if (folder === FolderType.interesting) {
        return isActive ? (
            <BrIcon
                iconName={IconName.BrStarRoundedIcon}
                iconColor={activeIconColor}
                iconSize={40}
            />
        ) : (
            <BrIcon iconName={IconName.BrStarBorderRoundedIcon} iconSize={40} />
        );
    }
    if (folder === FolderType.exported) {
        return isActive ? (
            <BrIcon
                iconName={IconName.BrCheckCircleIcon}
                iconColor={activeIconColor}
                iconSize={32}
            />
        ) : (
            <BrIcon
                iconName={IconName.BrCheckCircleOutlineIcon}
                iconSize={32}
            />
        );
    }
    return <></>;
};

export const folderMap: Record<FolderType, FolderType[]> = {
    inbox: [FolderType.inbox],
    interesting: [FolderType.interesting, FolderType.snoozed],
    snoozed: [FolderType.interesting, FolderType.snoozed],
    exported: [FolderType.exported, FolderType.done],
    done: [FolderType.exported, FolderType.done],
    not_interesting: [FolderType.notInteresting],
};
export const isFolderActive = (
    folder: FolderType,
    selectedFolder: FolderType | undefined,
): boolean => {
    return !!selectedFolder && folderMap[folder].includes(selectedFolder);
};
