import { makeAutoObservable } from 'mobx';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { DealContactsStore } from 'src/app-features/contact/data/stores/deal-contacts.store';
import { DealsApi } from 'src/data/api/deal/deal.api';
import {
    LinkedFieldType,
    UserAchievementName,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    MixpanelEventLocation,
    MixpanelEventName,
} from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { EventType } from 'src/data/services/pendo/pendo.model';
import { PendoService } from 'src/data/services/pendo/pendo.service';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { ObjectivesStore } from 'src/data/stores/objectives/objectives.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ObjectiveUpdateParams } from 'src/domain/features/deal-view/deal-view.feature';
import {
    Deal,
    DealState,
    getPauseData,
} from 'src/domain/models/deal/deal.model';
import { Objective } from 'src/domain/models/objective/objective.model';
import { SuccessState } from 'src/domain/models/success-state/success-state.model';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export interface StageObjectivesFeature {
    isUpdateObjectiveLoading: boolean;
    isLoadingObjectives: boolean;
    isCongratsModalOpened: boolean;
    objectivesMap: Map<string, Objective>;
    setIsCongratsModalOpened: (flag: boolean) => void;
    moveDeal: (
        deal: Deal,
        toNextStage?: boolean,
        onSuccess?: () => void,
    ) => Cancellable;
    getObjectiveIdLinkedToNativeParameter: (name: string) => string | undefined;
    getObjectiveIdLinkedToCustomParameter: (name: string) => string | undefined;
    getObjectives: (dealId: string) => Cancellable; //
    getObjectiveLoadingState: (objectiveId: string) => boolean;
    trackConfirmObjectiveEvent: () => void;
    updateObjective: (
        objectiveId: string,
        update: ObjectiveUpdateParams,
        dealId: string,
        location?: string,
    ) => void;
    updateObjectiveAsync: (
        objectiveId: string,
        update: ObjectiveUpdateParams,
        dealId: string,
        location?: string,
    ) => Promise<void>;
}

export class StageObjectivesFeatureImpl implements StageObjectivesFeature {
    isUpdateObjectiveLoading = false;
    isLoadingObjectives = false;
    isCongratsModalOpened = false;

    get objectivesMap() {
        return this.objectivesStore.objectivesMap;
    }
    get selectedObjectiveId() {
        return this.objectivesStore.selectedObjectiveId;
    }

    constructor(
        private dealsStore: DealsStore,
        private contactsStore: DealContactsStore,
        private pipelineStore: PipelineStore,
        private dealsApi: DealsApi,
        private objectivesStore: ObjectivesStore,
        private pendoService: PendoService,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    setIsLoadingObjectives = (flag: boolean) => {
        this.isLoadingObjectives = flag;
    };

    setIsCongratsModalOpened = (flag: boolean) => {
        this.isCongratsModalOpened = flag;
    };

    setUpdateObjectiveLoading = (loading: boolean, objectiveId: string) => {
        this.isUpdateObjectiveLoading = loading;
        this.objectivesStore.objectivesUpdateLoadingMap.set(
            objectiveId,
            loading,
        );
    };

    moveDeal = (
        deal: Deal,
        toNextStage = true,
        onSuccess?: () => void,
    ): Cancellable => {
        const pipeline = this.pipelineStore.getPipeline(deal.pipeline.id);
        if (!pipeline) {
            return emptyCancellable;
        }

        const { stageId: currentStageId } = deal;
        const { stageIdsInOrder, stageMap } = pipeline.config.stageConfig;
        const currentStage = stageMap.get(currentStageId);
        if (!currentStage || deal.state === DealState.done) {
            return emptyCancellable;
        }

        const idx = stageIdsInOrder.findIndex(
            (stageId) => stageId === currentStageId,
        );
        const newIdx = idx + (toNextStage ? 1 : -1);
        if (idx < 0 || stageIdsInOrder[newIdx] === undefined) {
            return emptyCancellable;
        }
        this.objectivesStore.selectedObjectiveId = null;
        const nextStageId = stageIdsInOrder[newIdx];
        return this.moveDealToStage(deal, nextStageId, onSuccess);
    };

    getObjectiveIdLinkedToNativeParameter = (name: string) => {
        return this.objectivesStore.getObjectiveIdLinkedToParameter(
            name,
            LinkedFieldType.DealNative,
        );
    };

    getObjectiveIdLinkedToCustomParameter = (name: string) => {
        return this.objectivesStore.getObjectiveIdLinkedToParameter(
            name,
            LinkedFieldType.DealCustom,
        );
    };

    getObjectives = (dealId: string) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) {
            return emptyCancellable;
        }

        return handleRequest(
            this.dealsApi.getDealObjectives,
            { id: dealId },
            (objectives) => this.onGetObjectives(deal, objectives),
            this.setIsLoadingObjectives,
            (error) => this.baseStore.onRequestFailed('get-objectives', error),
        );
    };
    getObjectiveLoadingState = (objectiveId: string) => {
        return !!this.objectivesStore.objectivesUpdateLoadingMap.get(
            objectiveId,
        );
    };

    updateObjective = (
        objectiveId: string,
        update: ObjectiveUpdateParams,
        dealId: string,
        location?: string,
    ) => {
        handleRequest(
            this.dealsApi.updateObjective,
            { objectiveId, update },
            this.onUpdateObjective(objectiveId, update, dealId, location),
            (loading) => this.setUpdateObjectiveLoading(loading, objectiveId),
            (error) =>
                this.baseStore.onRequestFailed('update-objective', error),
        );
    };

    updateObjectiveAsync = async (
        objectiveId: string,
        update: ObjectiveUpdateParams,
        dealId: string,
        location?: string,
    ) => {
        const response = await handleRequestAsync(
            this.dealsApi.updateObjective,
            { objectiveId, update },
            (loading) => this.setUpdateObjectiveLoading(loading, objectiveId),
            (error) =>
                this.baseStore.onRequestFailed('update-objective', error),
        );
        response &&
            this.onUpdateObjective(
                objectiveId,
                update,
                dealId,
                location,
            )(response);
    };

    trackConfirmObjectiveEvent = () => {
        this.pendoService.trackEvent(EventType.SO_CONFIRMED);
    };

    /// internal functions
    moveDealToStage = (
        deal: Deal,
        newStageId: string,
        onSuccess?: () => void,
    ): Cancellable => {
        return handleRequest(
            this.dealsApi.moveDealToStage,
            { dealId: deal.id, stageId: newStageId },
            () => this.onSuccessDealStageChange(deal, newStageId, onSuccess),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('move-deal-to-stage', error),
        );
    };

    getStageUpdateDate = (deal: Deal) => {
        handleRequest(
            this.dealsApi.getDealStageUpdateDate,
            { id: deal.id },
            (stageUpdatedDate: string) => {
                if (stageUpdatedDate) {
                    deal.stageUpdatedDate = new Date(stageUpdatedDate);
                }
            },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('get-stage-update-date', error),
        );
    };

    autoUpdateObjectives = (dealId: string) => {
        /// obtain all objectives with deal roles
        const roleObjectives = Array.from(
            this.objectivesStore.objectivesMap.values(),
        ).filter(
            (obj) =>
                !!obj.linkedField &&
                (obj.linkedField.type === LinkedFieldType.CompanyRole ||
                    obj.linkedField.type === LinkedFieldType.ContactRole),
        );
        /// obtain all contact, filter with roles to avoid checking useless contacts
        const allContacts = [
            ...this.contactsStore.personsMap.values(),
            ...this.contactsStore.companiesMap.values(),
        ].filter((contact) => !!contact.role);
        roleObjectives.forEach((obj) => {
            const role = obj.linkedField?.name;
            const hasAssignedSORole = allContacts.find(
                (contact) => contact.role === role,
            );
            if (hasAssignedSORole && obj.state !== SuccessState.success) {
                /// update SO to success if a contact fulfills requirements

                this.updateObjective(
                    obj.id,
                    { state: SuccessState.success },
                    dealId,
                    MixpanelEventLocation.BackgroundTrigger,
                );
            } else if (
                !hasAssignedSORole &&
                obj.state === SuccessState.success
            ) {
                /// update SO to undecieded in key deal role is unassigned
                this.updateObjective(
                    obj.id,
                    { state: SuccessState.undecided },
                    dealId,
                    MixpanelEventLocation.BackgroundTrigger,
                );
            }
        });
    };
    onSuccessDealStageChange = (
        deal: Deal,
        newStageId: string,
        onSuccess?: () => void,
    ) => {
        deal.stageId = newStageId;

        if (deal.pauseData.isPaused) {
            deal.pauseData = getPauseData(null);
        }

        this.getObjectives(deal.id);
        this.getStageUpdateDate(deal);

        // we need to update pipeline with updated deal
        this.pipelineStore.setNeedToRequestPipelines(true);
        this.dealsStore.setNeedToSyncDealAlert(true);
        onSuccess?.();
        this.achievementService.call(UserAchievementName.BookedFirstMeeting);
    };

    onGetObjectives = (deal: Deal, objectives: Objective[]) => {
        this.objectivesStore.clear();
        this.objectivesStore.setObjectives(objectives);
        deal.objectivesIds = objectives.map((objective) => objective.id);
        this.autoUpdateObjectives(deal.id);
    };

    enableAutomaticStageMoveModal = (location: MixpanelEventLocation) => {
        return (
            location === MixpanelEventLocation.BackgroundTrigger ||
            location === MixpanelEventLocation.StageObjectivesCard ||
            location === MixpanelEventLocation.SegmentControl
        );
    };
    onUpdateObjective =
        (
            objectiveId: string,
            update: ObjectiveUpdateParams,
            dealId: string,
            location?: string,
        ) =>
        (ok: boolean) => {
            if (!ok) {
                return;
            }
            const deal = this.dealsStore.dealsMap.get(dealId);
            const { status, state } = update;
            const oldObjective =
                this.objectivesStore.objectivesMap.get(objectiveId);

            if (!oldObjective) {
                return;
            }

            if (deal && state !== SuccessState.undecided && location && state) {
                this.trackUpdateObjective(
                    objectiveId,
                    state,
                    deal,
                    location,
                    oldObjective,
                );
            }

            const newObjective = {
                ...oldObjective,
            };
            if (status !== undefined) {
                newObjective.status = status;
            }
            if (state !== undefined) {
                newObjective.state = state;
            }

            this.objectivesStore.objectivesMap.set(
                oldObjective.id,
                newObjective,
            );

            if (
                deal &&
                state === SuccessState.success &&
                this.enableAutomaticStageMoveModal(
                    location as MixpanelEventLocation,
                )
            ) {
                this.checkObjectivesCompletion(deal);
            }

            this.dealsStore.setNeedToSyncDealAlert(true);
        };

    trackUpdateObjective = (
        objectiveId: string,
        state: SuccessState,
        deal: Deal,
        location: string,
        oldObjective: Objective,
    ) => {
        const pipeline = this.pipelineStore.getPipeline(deal.pipeline.id);
        const stageObjectiveName =
            this.objectivesMap.get(objectiveId)?.criteria ?? '';
        const currentStageName =
            pipeline?.config.stageConfig.stageMap.get(deal.stageId)?.name ?? '';
        const label =
            state === SuccessState.success
                ? MixpanelEventName.ConfirmedStageObjective
                : MixpanelEventName.FailedStageObjective;

        this.mixpanelService.trackEvent(label, {
            dealId: deal.id,
            pipelineId: deal.pipeline.id,
            stageObjectiveId: objectiveId,
            stageObjectiveName,
            currentStageId: deal.stageId,
            currentStageName,
            stageObjectiveConfirmOrFailLocation: location,
            stageObjectiveType: oldObjective.type,
        });
    };

    getDoneCount = (objectives: Objective[]): number =>
        objectives.filter((obj) => obj.state === SuccessState.success).length;

    checkObjectivesCompletion = (deal: Deal) => {
        const objectives = deal.objectivesIds
            .map((id) => this.objectivesMap.get(id))
            .filter(isNonNullable);
        if (
            objectives.length > 0 &&
            this.getDoneCount(objectives) === objectives.length
        ) {
            this.setIsCongratsModalOpened(true);
        }
    };
}
