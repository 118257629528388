import { handleRequest } from 'src/utils/handle-request.utils';
import {
    INeuralSearchFeature,
    NeuralSearchPayload,
    NeuralSearchStatus,
} from './neural-search-interface';
import { INeuralSearchApi } from '../data/neural-search.api';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { NeuralSearchResponse } from '../data/model';
import { makeAutoObservable } from 'mobx';
import { doNothing } from 'src/utils/function.utils';
import {
    EventProps,
    MixpanelService,
} from 'src/data/services/mixpanel/mixpanel.service';
import { PostHog } from 'posthog-js';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { DateRange } from '@buildingradar/ui_library/date-range-picker';
import { addMonths } from 'date-fns';
import { formatToApiDateFormat } from 'src/utils/datetime.utils';

const NEURAL_SEARCH_REQUEST_KEY = 'neural-search';
export class NeuralSearchFeature implements INeuralSearchFeature {
    searchResults: NeuralSearchResponse[] = [];
    searchStatus: NeuralSearchStatus = 'not-started';

    searchTerm = '';
    range: DateRange | undefined = {
        from: addMonths(new Date(), -1),
        to: new Date(),
    };

    constructor(
        private neuralSearchApi: INeuralSearchApi,
        private baseStore: IBaseStore,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }

    removeSearchResult = (result: NeuralSearchResponse) => {
        this.searchResults = this.searchResults.filter((r) => r !== result);
    };

    runNeuralSearch = (params: NeuralSearchPayload) => {
        const { prompt, startDate, endDate } = params;
        this.searchStatus = 'loading';
        this.searchResults = [];
        handleRequest(
            this.neuralSearchApi.runNeuralSearch,
            {
                prompt,
                startDate,
                endDate,
                onNextValue: (value) => {
                    this.searchResults.push(value);
                },
                signal: new AbortController().signal,
            },
            () => {
                this.searchStatus = 'done';
                this.trackNeuralSearchResults();
            },
            doNothing,
            (error) => {
                if (!error) return;
                this.baseStore.onRequestFailed('neural-search', error);
                this.searchStatus = 'error';
            },
            NEURAL_SEARCH_REQUEST_KEY,
        );
    };

    /**
     * Get the search session ID of the first search result.
     * If there are no search results, returns undefined.
     * The searchId will be the same for the group of results
     */
    get neuralSearchId(): string | undefined {
        if (!this.searchResults.length) {
            return undefined;
        }

        return this.searchResults[0].searchId;
    }

    setSearchTerm = (searchTerm: string) => {
        this.searchTerm = searchTerm;
    };

    setRange = (range: DateRange | undefined) => {
        this.range = range;
    };

    trackNeuralSearchLaunch = (posthog?: PostHog) => {
        this.mixpanelService.trackEvent(MixpanelEventName.LaunchedNeuralSearch);
        posthog?.capture(MixpanelEventName.LaunchedNeuralSearch);
    };

    trackNeuralSearchResults = () => {
        const props: EventProps = {
            prompt: this.searchTerm,
            selectedDates:
                this.range?.from && this.range?.to
                    ? `${formatToApiDateFormat(this.range.from)} to ${formatToApiDateFormat(this.range.to)}`
                    : undefined,
            numberItemsDeliveredNeuralSearch: this.searchResults.length,
            neuralSearchId: this.neuralSearchId,
        };

        this.mixpanelService.trackEvent(
            MixpanelEventName.SearchedWithNeuralSearch,
            props,
        );
    };
}
