import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';

import { ValueMilestoneFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { Pipeline } from 'src/domain/models/pipeline/pipeline.model';
import { SecondaryNavbarLayout } from 'src/presentation/shared/secondary-navbar-layout/secondary-navbar-layout.component';
import { withFeatures } from 'src/utils/component.utils';
import { createCtx } from 'src/utils/context.utils';
import { Cancellable, emptyCancellable } from 'src/utils/handle-request.utils';
import { useCancellableRequest } from 'src/utils/hooks/use-demo-hooks';
import { IChildren } from 'src/utils/react.utils';

const [usePipelines, PipelinesProvider] = createCtx<Pipeline[]>();
export default usePipelines;

interface PipelinesProviderComponentProps extends IChildren {
    pipelines: Pipeline[];
    needToRequestPipelines: boolean;
    valueMilestones: ValueMilestoneFragment[] | undefined;
    requestPipelines: () => Cancellable;
    getValueMilestones: () => void;
}

const PipelinesProviderComponent: FC<PipelinesProviderComponentProps> =
    observer(
        ({
            pipelines,
            needToRequestPipelines,
            valueMilestones,
            requestPipelines,
            getValueMilestones,
            children,
        }) => {
            const loadPipeline = useCallback(() => {
                if (needToRequestPipelines) {
                    return requestPipelines();
                }
                return emptyCancellable;
            }, [needToRequestPipelines, requestPipelines]);

            useCancellableRequest(loadPipeline, [loadPipeline]);

            useEffect(() => {
                getValueMilestones();
            }, [getValueMilestones]);

            if (valueMilestones === undefined || pipelines.length === 0) {
                return <SecondaryNavbarLayout title="common.loading" />;
            }
            return (
                <PipelinesProvider value={pipelines}>
                    {children}
                </PipelinesProvider>
            );
        },
    );

export const PipelinesProviderContainer = withFeatures(
    PipelinesProviderComponent,
)((f) => ({
    pipelines: f.pipelineFeature.pipelines,
    needToRequestPipelines: f.pipelineFeature.needToRequestPipelines,
    arePipelinesLoading: f.pipelineFeature.arePipelinesLoading,
    valueMilestones: f.pipelineFeature.valueMilestones,
    requestPipelines: f.pipelineFeature.requestPipelines,
    getValueMilestones: f.pipelineFeature.getValueMilestones,
}));
