import { Signpost, BellRing } from 'lucide-react';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { isDateOverdue } from 'src/utils/datetime.utils';
import { cn } from 'src/utils/tailwindcss.utils';

interface ReminderBadgeProps {
    dueDate: Date;
}

export const ReminderBadge: FC<ReminderBadgeProps> = observer(({ dueDate }) => {
    const isOverdue = isDateOverdue(dueDate);

    return (
        <div
            className={cn(
                'flex size-10 items-center justify-center rounded-full bg-indigo-200',
                {
                    'bg-red-200': isOverdue,
                },
            )}
        >
            {isOverdue ? (
                <BellRing className="text-red-600" fill="#dc2626" />
            ) : (
                <Signpost className="text-indigo-600" fill="#4f46e5" />
            )}
        </div>
    );
});
