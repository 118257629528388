import { TFunction } from 'react-i18next';

import { Stage } from 'src/domain/models/deal-stage/deal-stage.model';
import { UserLike } from 'src/domain/models/user/user.model';
import {
    addSpaceAroundControlCharacters,
    cleanUpSpecialCharacters,
} from 'src/utils/string.utils';
import { goToMailTo, isUrl } from 'src/utils/url.utils';

import { Comment } from './comment.model';
import { isEmail } from 'src/utils/email.utils';

export const TRIGGER_CHARACTER = '@';
export const MARKED_USER_ID = `<${TRIGGER_CHARACTER}`;
const USER_TAG = /^<@.*>/;

interface FoundMention {
    mentionedUserId: number;
    restSubstring: string;
}

export const getUserIdAndRest = (text: string): FoundMention | undefined => {
    const mentionedUser: RegExpMatchArray | null = text.match(USER_TAG);
    if (mentionedUser) {
        /*
         * Check if string is concatenated with user tag, if yes fetch it and add to user name.
         * Otherwise, set it to empty string.
         */
        const substringAfterUserId =
            text.substring(mentionedUser[0].length) || '';

        const userId = Number.parseInt(mentionedUser[0].slice(2, -1));
        return {
            mentionedUserId: userId,
            restSubstring: substringAfterUserId,
        };
    }
    return undefined;
};

export const parseComment = (
    textWithMentions: string,
    users: UserLike[],
    t: TFunction<'translation', undefined>,
    userNameFormatter: (user: UserLike | undefined) => JSX.Element | string,
    urlFormatter: (
        url: string,
        onClickLink?: (url: string) => void,
    ) => JSX.Element | string,
    emailFormatter: (
        url: string,
        onClickLink?: (url: string) => void,
    ) => JSX.Element | string,
    onClickLink?: (url: string) => void,
): (JSX.Element | string)[] => {
    const wordList =
        addSpaceAroundControlCharacters(textWithMentions).split(' ');
    const wordOptions: (JSX.Element | string)[] = [];

    wordList.forEach((word) => {
        if (word.includes(MARKED_USER_ID)) {
            const userMention = getUserIdAndRest(word);
            if (userMention && userMention.mentionedUserId) {
                const user = users.find(
                    (user) => user.itemId === userMention.mentionedUserId,
                );
                const componentWord = userNameFormatter(
                    user ?? {
                        itemId: userMention.mentionedUserId,
                        firstName: t('common.inactive_user'),
                        lastName: t('common.inactive_user'),
                        email: t('common.inactive_user'),
                    },
                );
                const rest = userMention.restSubstring
                    ? ` ${userMention.restSubstring}`
                    : '';
                wordOptions.push(componentWord);
                if (rest) {
                    wordOptions.push(rest);
                }
            }
        } else {
            wordOptions.push(
                isUrl(word)
                    ? urlFormatter(word, onClickLink)
                    : isEmail(word)
                      ? emailFormatter(word, (email) =>
                            goToMailTo({ email, body: '' }),
                        )
                      : word,
            );
        }
    });

    return wordOptions.map((word) => {
        if (typeof word === 'string') {
            return cleanUpSpecialCharacters(word);
        }
        return word;
    });
};

export const sortCommentByDate = (
    commentA: Comment,
    commentB: Comment,
): number => {
    if (commentA.created < commentB.created) {
        return 1;
    } else if (commentA.created > commentB.created) {
        return -1;
    }
    return 0;
};

export const createAllObjectivesCompletedPrefilledText = (
    stages: Stage[],
    currentStageIndex: number,
    t: TFunction,
) => {
    return `${t('common.stage')} ${stages[currentStageIndex]?.name} → ${
        stages[currentStageIndex + 1]?.name
    }: `;
};
