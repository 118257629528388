import { IconName } from '@buildingradar/br_component_lib';

import { IActionFeatureConfiguration } from 'src/domain/models/account-configuration/action-feature-configuration/action-feature-configuration.model';

export enum ContactQuickActionsCategory {
    Research = 'Research',
    Outreach = 'Outreach',
    FindPeople = 'Find people',
}

export enum ActionFeatureLaunchSource {
    StageObjectives = 'Stage Objectives',
    ContactCardLinkedCompanyParameter = 'Contact Card Parameter',
    ContactCardQuickActionMenus = 'Contact Card Quick Action Menus',
    CompanyCardQuickActionMenus = 'Company Card Quick Action Menus',
    CompanyCardLinkedContactParameter = 'Company Card Parameter',
    ContactCardKebabMenu = 'Contact Card Kebab Menu',
    ContactDetailView = 'Contact Detail View',
    CompanyDetailView = 'Company Detail View',
    DetailKebabMenu = 'Detail Kebab Menu',
    HelperFlyoutLaunchButton = 'Helper Flyout Launch Button',
    Newsfeed = 'Newsfeed',
    ReminderUrl = 'Reminder URL',
    NotificationFlyoutReminderTab = 'Notification Flyout Reminder Tab',
    RemindersChipDealView = 'Reminders Chip Deal View',
    ReminderCreatedToast = 'Reminder Created Toast',
    DealTableReminderView = 'Deal Table',
    EnablementPanelContactExtraction = 'Contact extractor',
    ProjectNewsfeed = 'Project Newsfeed',
    ProjectNewsfeedArticleSummary = 'Project Newsfeed Article Summary',
    DealNewsfeedArticleSummary = 'Deal Newsfeed Article Summary',
    ProjectCardArticleSummary = 'Project Card Article Summary',
    ProjectPageArticleSummary = 'Project Page Article Summary',
    ContactEnrichmentSearchRefinement = 'Contact enrichment search refinement',
    DealNavigationBar = 'Deal Navigation Bar',
    NextStepsActivityHistory = 'Next Steps Activity History',
}

export const ActionFeatureIdentifierNamesMap: Record<
    ActionFeaturesIdentifiers,
    string
> = {
    hubspot_lookup: 'CRM Lookup',
    msdynamics_lookup: 'CRM Lookup',
    salesforce_classic_lookup: 'CRM Lookup',
    google_lookup: 'Google Lookup',
    linkedin_lookup: 'LinkedIn Lookup',
    salesforce_lookup: 'CRM Lookup',
    xing_lookup: 'XING Lookup',
    save_contact_to_phone: 'Send contact to phone',
    google_enrichment: 'Google enrichment',
    email_templates: 'Static email templates',
    xray: 'Article Wizard',
    contact_extractor: 'Contact Extractor',
    value_prop_outreach: 'Value Prop Outreach email',
    contact_panel: 'Contact Panel',
    reference_details: 'Reference Details',
    download_contact_vcard: 'Download contact',
    direct_google_maps: 'Google Maps',
    direct_weblink: 'Direct weblink',
    contact_enrichment: 'Contact enrichment',
};

export enum ActionFeaturesIdentifiers {
    hubspot_lookup = 'hubspot_lookup',
    msdynamics_lookup = 'msdynamics_lookup',
    salesforce_classic_lookup = 'salesforce_classic_lookup',
    google_lookup = 'google_lookup',
    linkedin_lookup = 'linkedin_lookup',
    salesforce_lookup = 'salesforce_lookup',
    xing_lookup = 'xing_lookup',
    save_contact_to_phone = 'save_contact_to_phone',
    google_enrichment = 'google_enrichment',
    email_templates = 'email_templates',
    xray = 'xray',
    contact_extractor = 'contact_extractor',
    value_prop_outreach = 'value_prop_outreach',
    /**
     * This is not really a quick action. It's here to simplify how we open the contact panel.
     * We should probably move this to a different place.
     */
    contact_panel = 'contact_panel',
    /**
     * This is not really a quick action. It's here to simplify how we open the contact panel.
     * We should probably move this to a different place.
     */
    reference_details = 'reference_details',
    download_contact_vcard = 'download_contact_vcard',
    direct_google_maps = 'direct_google_maps',
    direct_weblink = 'direct_weblink',
    contact_enrichment = 'contact_enrichment',
}

interface ActionFeatureProps {
    icon?: IconName;
    tooltip?: React.ReactNode | string | undefined;
    label?: React.ReactNode | string | undefined;
    callbackHandlerId?: string;
    shouldDisplayFeature?: boolean;
    disabled?: boolean;
    iconSize?: number;
}

export interface IActionFeatureOptionConfiguration
    extends IActionFeatureConfiguration,
        ActionFeatureProps {}
