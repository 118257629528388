import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { TextPreviewStyled } from 'src/presentation/shared/text-preview/styles';

export const FormInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const NoteFieldStyled = styled(Box)({
    [`${TextPreviewStyled}:after`]: {
        borderWidth: '1px !important',
    },
});
