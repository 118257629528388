import { makeAutoObservable } from 'mobx';

import { NotificationsApi } from 'src/data/api/notifications/notifications.api';
import { LocalStorageService } from 'src/data/services/local-storage/local-storage.service';
import { NotificationsStore } from 'src/data/stores/notifications/notifications.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { Mention } from 'src/domain/models/mention/mention.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { sortUncompletedRemindersByDueDateAsc } from 'src/presentation/modules/notifications-flyout/components/reminders-dashboard/reminder-card/reminder-due-date.helper';
import { handleRequest } from 'src/utils/handle-request.utils';

import { NotificationsFeature } from './notifications-interface.feature';

export class NotificationsFeatureImpl implements NotificationsFeature {
    isOpened = false;
    isLoading = false;
    notificationsAreChecked = true;
    isFetchingReminders = false;

    constructor(
        private notificationsApi: NotificationsApi,
        private notificationStore: NotificationsStore,
        private localStorageService: LocalStorageService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    requestMentions = () => {
        handleRequest(
            this.notificationsApi.getMentions,
            {},
            this.notificationStore.setMentions,
            this.setLoading,
            (error) =>
                this.baseStore.onRequestFailed('request-mentions', error),
        );
    };

    setNotificationsAreChecked = (flag: boolean) => {
        this.notificationsAreChecked = flag;
        this.localStorageService.setNotificationsAreChecked(flag);
    };

    setLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    toggleNotificationsOpened = (flag: boolean) => {
        if (flag) {
            this.setNotificationsAreChecked(true);
        }
        this.isOpened = flag !== undefined ? flag : !this.isOpened;
    };

    requestReminders = () => {
        handleRequest(
            this.notificationsApi.getReminders,
            {},
            this.notificationStore.setReminders,
            this.setIsFetchingReminders,
            (error) =>
                this.baseStore.onRequestFailed('request-reminders', error),
        );
    };

    setIsFetchingReminders = (fetching: boolean) =>
        (this.isFetchingReminders = fetching);

    get mentions(): Mention[] {
        return this.notificationStore.mentions;
    }

    get uncompletedReminders(): Reminder[] {
        return sortUncompletedRemindersByDueDateAsc(
            this.notificationStore.reminders,
        );
    }

    get overdueReminders(): Reminder[] {
        const today = new Date();
        const overdueReminders = this.uncompletedReminders.filter(
            ({ dueDate }) => dueDate <= today,
        );
        return overdueReminders;
    }

    get upcomingReminders(): Reminder[] {
        const today = new Date();
        const upcomingReminders = this.uncompletedReminders.filter(
            ({ dueDate }) => dueDate > today,
        );
        return upcomingReminders;
    }
}
