import { ReminderQl } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

import {
    ReminderTargetType,
    TargetTypeSdkToModelMap,
} from './reminder-target-type';

export class Reminder {
    id?: string;
    creator: number;
    assignee: number;
    description: string;
    dueDate: Date;
    createdOn: Date;
    updatedOn: Date;
    completedOn?: Date;
    target: ReminderTarget;
    assigneeId: number;

    constructor(raw: ReminderQl) {
        const dueDate = new Date(raw.dueDate);
        dueDate.setHours(0, 0, 0, 0);

        this.id = raw.id;
        this.creator = raw.creator;
        this.description = raw.description;
        this.dueDate = dueDate;
        this.createdOn = new Date(raw.createdDate);
        this.updatedOn = new Date(raw.updatedDate);
        this.completedOn = raw.completedDate
            ? new Date(raw.completedDate)
            : undefined;
        this.assignee = raw.assignee;
        this.target = {
            id: raw.target.id,
            type: TargetTypeSdkToModelMap[raw.target.type],
            title: raw.target.title,
        };
        this.assigneeId = raw.assignee;
    }
}

/**
 * Holds the necessary data to update a existing reminder
 */

export interface UpdateReminderData {
    description?: string;
    dueDate: Date;
    assigneeId: number;
}

/**
 * Holds the necessary data to create a new reminder
 */
export interface CreateReminderData extends UpdateReminderData {
    target: ReminderTarget;
    launchSource: ReminderLaunchSource;
}

interface ReminderTarget {
    id: string;
    type: ReminderTargetType;
    title?: string;
}

export type ReminderLaunchSource =
    | 'Deal table'
    | 'Deal Kebab Menu'
    | 'Deal header'
    | '1 Click Next Actions'
    | 'Next Step Url'
    | 'Notification Flyout'
    | 'Next Step Created Toaster'
    | 'Contact extraction'
    | 'Activity section'
    | 'Not specified';
