import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Mention } from 'src/domain/models/mention/mention.model';
import { getUserFullName, User } from 'src/domain/models/user/user.model';
import {
    ScrollableListWrapper,
    SectionListTitleStyled,
} from 'src/presentation/modules/notifications-flyout/components/notifications-panel/notifications-panel.styled';
import { EmptyNotificationsList } from 'src/presentation/modules/notifications-flyout/components/shared/empty-notifications-list/empty-notifications-list.component';
import { NotificationPlaceholderComponent } from 'src/presentation/modules/notifications-flyout/components/shared/notification-placeholder/notification-placeholder.component';
import { Spacing } from 'src/resources/styles/spacing';
import { multiple } from 'src/utils/array.utils';

import { MentionContainer } from './mention/mention.container';

interface MentionsDashboardProps {
    mentions: Mention[];
    isLoading: boolean;
    user?: User;
    requestMentions: () => void;
}

export const MentionsDashboard: FC<MentionsDashboardProps> = observer(
    ({ mentions, isLoading, user, requestMentions }) => {
        useEffect(() => {
            requestMentions();
        }, [requestMentions]);

        const myName = getUserFullName(user);

        const { t } = useTranslation();

        const tip = useMemo(
            () => ({
                tipLabel: t(
                    'notification_flyout.mentions_tab.empty_mentions.tip_label',
                ),
                tipText: t(
                    'notification_flyout.mentions_tab.empty_mentions.tip_text',
                    {
                        current_user_name: myName,
                    },
                ),
            }),
            [myName, t],
        );

        const shouldDisplayEmptyMentionsComponent =
            !mentions.length && !isLoading;
        const shouldDisplaySectionTitle =
            isLoading || (!isLoading && !!mentions.length);

        return (
            <Box
                sx={{
                    width: '100%',
                    marginY: Spacing.s2,
                }}
            >
                <ScrollableListWrapper offset={200}>
                    {shouldDisplayEmptyMentionsComponent ? (
                        <EmptyNotificationsList
                            title={t(
                                'notification_flyout.mentions_tab.empty_mentions.title',
                            )}
                            subtitle={t(
                                'notification_flyout.mentions_tab.empty_mentions.subtitle',
                            )}
                            tip={tip}
                        />
                    ) : (
                        <>
                            {shouldDisplaySectionTitle && (
                                <SectionListTitleStyled>
                                    {t(
                                        'notification_flyout.mentions_tab.title',
                                    )}
                                </SectionListTitleStyled>
                            )}
                            <ul className="h-full list-none">
                                {isLoading
                                    ? multiple(
                                          (index: number) => (
                                              <NotificationPlaceholderComponent
                                                  key={index}
                                              />
                                          ),
                                          3,
                                      )
                                    : mentions.map((m, i) => (
                                          <MentionContainer
                                              key={i}
                                              mention={m}
                                          />
                                      ))}
                            </ul>
                        </>
                    )}
                </ScrollableListWrapper>
            </Box>
        );
    },
);
