import { BrDrawer, DrawerVariant } from '@buildingradar/br_component_lib';
import { DatePicker } from '@buildingradar/ui_library/date-picker';
import { Textarea } from '@buildingradar/ui_library/textarea';
import { useMediaQuery } from '@mui/material';
import { X } from 'lucide-react';
import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReminderFlyoutDataContext } from 'src/domain/features/actions/quick-actions/reminders/reminders-interface.feature';
import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { UserAssignmentDropdown } from 'src/presentation/shared/user-assignment-dropdown/user-assignment-dropdown.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { getTomorrow, isDateOverdue } from 'src/utils/datetime.utils';
import { getRoot } from 'src/utils/element.utils';
import useCurrentUser from 'src/presentation/modules/providers/user.provider';
import { Button } from '@buildingradar/ui_library/button';
import {
    CreateReminderData,
    UpdateReminderData,
} from 'src/domain/models/reminder/reminder.model';
import { ReminderTargetType } from 'src/domain/models/reminder/reminder-target-type';
import { useLocation } from 'react-router-dom';

interface Props {
    isOpened: boolean;
    dataContext?: ReminderFlyoutDataContext;
    assignToUsers: Colleague[];
    onCloseRequested: () => void;
    onCreateReminderRequested: (
        data: CreateReminderData,
        dealId?: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    onUpdateReminderRequested: (
        reminderId: string,
        data: UpdateReminderData,
        dealId?: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    markAsCompleted: (
        reminderId: string,
        dealId: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    triggerPipelineDataRefresh: () => void;
}

export const ReminderFlyout: FC<Props> = observer(function Component({
    isOpened,
    dataContext,
    assignToUsers,
    onCloseRequested,
    onCreateReminderRequested,
    onUpdateReminderRequested,
    markAsCompleted,
    triggerPipelineDataRefresh,
}) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(MediaQuery.phone);
    const { itemId } = useCurrentUser();

    const { dealId, reminder, customInitialDescription, launchSource } =
        dataContext ?? {};

    const [description, setDescription] = useState('');

    const [dueDate, setDueDate] = useState<Date>();

    const [currentAssignedTo, setCurrentAssignedTo] = useState(itemId);

    const { pathname } = useLocation();
    const currentLocationWhenOpening = useRef<string>();

    useEffect(() => {
        if (isOpened && dealId && reminder && pathname.includes('/reminder')) {
            const newPath = pathname.replace(/\/reminder\/[^/]+/, '');
            window.history.replaceState(null, '', newPath);
        }
    }, [dealId, isOpened, pathname, reminder]);

    useEffect(() => {
        if (isOpened) {
            if (!currentLocationWhenOpening.current) {
                currentLocationWhenOpening.current = pathname;
                return;
            }

            if (pathname !== currentLocationWhenOpening.current) {
                currentLocationWhenOpening.current = undefined;
                onCloseRequested();
            }
        }
    }, [isOpened, onCloseRequested, pathname]);

    useEffect(() => {
        if (isOpened) {
            setDescription(
                reminder?.description ??
                    customInitialDescription ??
                    t('actions.reminders.note_default_text'),
            );
            setDueDate(reminder?.dueDate ?? getTomorrow());
            setCurrentAssignedTo(reminder?.assigneeId ?? itemId);
        } else {
            setDescription('');
            setDueDate(getTomorrow());
            setCurrentAssignedTo(itemId);
        }
    }, [
        customInitialDescription,
        isOpened,
        itemId,
        reminder?.assigneeId,
        reminder?.description,
        reminder?.dueDate,
        t,
    ]);

    const isEditMode = !!reminder;

    const isOverdue = !!reminder && isDateOverdue(reminder.dueDate);

    const onSubmit = () => {
        if (!dueDate) return;

        if (isEditMode) {
            onUpdateReminderRequested(
                reminder.id ?? '',
                {
                    description: description,
                    dueDate: new Date(dueDate),
                    assigneeId: currentAssignedTo,
                },
                dealId,
                () => {
                    triggerPipelineDataRefresh();
                },
            );
        } else {
            if (!dealId) return;

            onCreateReminderRequested(
                {
                    description: description,
                    dueDate: new Date(dueDate),
                    assigneeId: currentAssignedTo,
                    target: {
                        id: dealId,
                        type: ReminderTargetType.Deal,
                    },
                    launchSource: launchSource ?? 'Not specified',
                },
                dealId,
                () => {
                    triggerPipelineDataRefresh();
                },
            );
        }
        onCloseRequested();
    };

    const onCompleteReminder = () => {
        if (!reminder?.id || !reminder.target?.id) return;

        markAsCompleted(reminder.id, reminder.target.id, () => {
            triggerPipelineDataRefresh();
            onCloseRequested();
        });
    };

    return (
        <BrDrawer
            isDrawerOpened={isOpened}
            container={getRoot()}
            onCloseRequested={onCloseRequested}
            shouldDisplayBackButton={false}
            className="z-10"
            hideBackdrop
            showHeader={false}
            backgroundColor="var(--white)"
            variant={DrawerVariant.persistent}
            isMobile={isMobile}
            applyBoxShadow
        >
            <div className="flex h-full w-full flex-col md:w-[340px]">
                <div className="z-10 -mx-4 my-0 flex items-center justify-between border-b border-solid border-b-slate-200 bg-white p-4">
                    <h1 className="text-lg font-bold text-indigo-600">
                        {isEditMode
                            ? t('enablement_panel.reminders.title_edit_mode')
                            : t('enablement_panel.reminders.title_create_mode')}
                    </h1>
                    <div
                        className="size-10 rounded-full p-2 text-indigo-300 hover:cursor-pointer hover:bg-slate-100"
                        onClick={onCloseRequested}
                    >
                        <X />
                    </div>
                </div>
                <div className="hidden-scrollbar mt-4 flex flex-grow flex-col gap-4 overflow-y-auto px-1">
                    <Textarea
                        label={t('enablement_panel.reminders.note_input_label')}
                        placeholder={t(
                            'enablement_panel.reminders.note_input_placeholder',
                        )}
                        autoFocus
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <DatePicker
                        label={t(
                            'enablement_panel.reminders.due_date_input_label',
                        )}
                        placeholder={t(
                            'enablement_panel.reminders.due_date_input_placeholder',
                        )}
                        value={dueDate}
                        onChange={(date) => setDueDate(date)}
                    />
                    <div className="mt-2">
                        <span className="font-medium">
                            {t(
                                'enablement_panel.reminders.assign_to_input_label',
                            )}
                        </span>
                        <UserAssignmentDropdown
                            assignToUsers={assignToUsers}
                            currentAssignedTo={currentAssignedTo}
                            onUserSelected={setCurrentAssignedTo}
                        />
                    </div>
                </div>
                <div className="-mx-4 my-0 flex flex-col gap-4 p-4 shadow-top">
                    <Button
                        variant={!isOverdue ? 'standard' : 'outline'}
                        className="w-full"
                        onClick={onSubmit}
                    >
                        {t(
                            isEditMode
                                ? 'enablement_panel.reminders.update_reminder_button_label'
                                : 'enablement_panel.reminders.set_reminder_button_label',
                        )}
                    </Button>
                    {isEditMode && (
                        <Button
                            variant={isOverdue ? 'standard' : 'outline'}
                            onClick={onCompleteReminder}
                        >
                            {t('next_steps.complete_action.button_text')}
                        </Button>
                    )}
                    <Button
                        variant="outline"
                        className="w-full"
                        onClick={onCloseRequested}
                    >
                        {t('common.close')}
                    </Button>
                </div>
            </div>
        </BrDrawer>
    );
});
